import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button, FormControl, FormLabel,
    Heading,
    HStack, Modal, ModalCloseButton, ModalContent,
    Text, Textarea, Tooltip, useDisclosure, useToast,
    VStack
} from "@chakra-ui/react";
import {ArrowUpIcon, PaperclipIcon, Settings as SettingsIcon} from "lucide-react";
import ImageResult from "~/apps/backend/components/ImageResult.tsx";
import Loader from "~/apps/backend/components/tools/Loader.tsx";
import {fetchModel, generateImage} from "~/store/modelSlice.ts";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "~/store";
import {useNavigate} from "react-router-dom";
import {DefaultModelSetting, GalleryImage} from "~/utils/types.ts";
import {Product} from "~/store/gallerySlice.ts";
import GallerySelect from "~/apps/backend/components/GallerySelect.tsx";
import SettingsDrawer from "~/apps/backend/components/SettingsDrawer.tsx";

interface GenerateImageProps {
    public_id: string | undefined;
    category: string | undefined;
    includeSettings: boolean;
    onImageSelect?: (image: any) => void;
}
const GenerateImage: React.FC<GenerateImageProps>= ({public_id, category, includeSettings, onImageSelect}) => {
    const { loading, model, latestResults } = useSelector((state: RootState) => state.models);
    const [modelRequiredTriggerWord, setModelRequiredTriggerWord] = useState<boolean>(false);
    const [remainingCredits, setRemainingCredits] = useState<number>(0);
    const [promptSetting, setPromptSetting] = useState<DefaultModelSetting | null>(null);
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [imageSetting, setImageSetting] = useState<DefaultModelSetting | null>(null);
    const [toolbarSettings, setToolbarSettings] = useState<DefaultModelSetting[]>([]);
    const { isOpen: isSettingsOpen, onOpen: onSettingsOpen, onClose: onSettingsClose } = useDisclosure();



    const { isOpen, onOpen, onClose } = useDisclosure();

    const [formData, setFormData] = useState<Record<string, string>>({});
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();

    useEffect(() => {
        if (!public_id || !category) return;
        dispatch(fetchModel({ publicId: public_id, category }));
    }, [dispatch, public_id, category]);

    useEffect(() => {
        if (model?.settings) {
            const foundPromptSetting = model.settings.find(s => s.input_type === "text" && !s.is_toolbar_setting);
            const foundImageSetting = model.settings.find(s => s.input_type === "image" && !s.is_toolbar_setting);
            const foundToolbarSettings = model.settings.filter(s => s.is_toolbar_setting);

            setPromptSetting(foundPromptSetting || null);
            setImageSetting(foundImageSetting || null);
            setToolbarSettings(foundToolbarSettings);

            // Initialize formData with default values for toolbar settings
            const defaultValues: Record<string, string> = {};
            foundToolbarSettings.forEach(setting => {
                if (setting.default_value) {
                    defaultValues[setting.key] = setting.default_value;
                }
            });
            setFormData(prev => ({ ...prev, ...defaultValues }));

            const isTRequired = !!(model.trigger_word?.trim());
            setModelRequiredTriggerWord(isTRequired);
            setRemainingCredits(model.remaining_credits);
        }


    }, [model]);



    const imageSelected = (image: GalleryImage | Product) => {
        if (imageSetting) {
            setSelectedImage(image.image_url);
            handleInputChange(imageSetting.key, image.image_url);
        }
        onClose();
    };

    const handleInputChange = (key: string, value: string) => {
        setFormData(prev => ({ ...prev, [key]: value }));
    };

    const validateForm = () => {
        if(!model.settings) {
            return {
                success: false,
                message: 'Model settings are not available.'
            }
        }

        if(remainingCredits < model.credit_cost) {
            return {
                success: false,
                message: 'Insufficient credits to generate image.'
            }
        }

        const response = {success: true, message: 'All fields are valid.'};
        let has_trigger_word = false;
        model.settings.forEach((setting) => {
            if(promptSetting && formData[promptSetting.key]?.toLowerCase().includes(model.trigger_word)) {
                has_trigger_word = true;
            }
            if(setting.required_input && (!formData[setting.key] || formData[setting.key].trim() === "") && !setting.is_toolbar_setting) {
                response.success = false;
                response.message += `${setting.key} is required. `;
            }
        });

        if(modelRequiredTriggerWord && response.success && promptSetting && !has_trigger_word) {
            response.success = false;
            response.message = `Prompt must contain the trigger word: ${model.trigger_word}. `;
        }

        return response;
    };

    const handleSubmit = async () => {
        const validationData = validateForm();
        if (!validationData.success) {
            toast({
                title: "Validation Error",
                description: validationData.message,
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const payload = {
            ...formData,
            ...{
                public_id: model?.public_id,
                category: model?.category,
            }
        }
        try {
            await dispatch(generateImage(payload)).unwrap();
            setRemainingCredits(remainingCredits - model.credit_cost);
            toast({
                title: "Image generated successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            const toolbarsettings = model.settings.filter(s => s.is_toolbar_setting);
            const default_settings: Record<string, string> = {}
            toolbarsettings.forEach(setting => {
                if (setting.default_value) {
                    default_settings[setting.key] = formData[setting.key] || setting.default_value;
                }
            });
            setFormData(default_settings);
            setSelectedImage("");
        } catch (error) {
            toast({
                title: "Failed to generate image",
                description: 'Please try again.',
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const isSubmitDisabled = () => !validateForm().success;

    const handleBuyCredits = () => {
        navigate('/c-panel/billing?alt=more');
    };
    const showCreditsWarning = remainingCredits <= 0 || (model?.credit_cost || 0) > remainingCredits;


    if (loading) return <Loader />;
    return (
        <>
            <VStack spacing={8} align="stretch">
                <HStack justify="space-between" align="center">
                    <Heading
                        size="lg"
                        color="white"
                    >
                        Generate image for {model?.name}
                    </Heading>
                    {includeSettings && (
                        <Button
                            leftIcon={<SettingsIcon size={16} />}
                            onClick={onSettingsOpen}
                            variant="ghost"
                            color="gray.300"
                            _hover={{ bg: 'gray.700' }}
                        >
                            Settings
                        </Button>
                    )}
                </HStack>

                {showCreditsWarning && (
                    <Alert
                        status="warning"
                        variant="solid"
                        bg="orange.700"
                        color="white"
                        borderRadius="lg"
                        flexDirection={{ base: "column", md: "row" }}
                        alignItems={{ base: "stretch", md: "center" }}
                        justifyContent="space-between"
                        py={4}
                        px={6}
                    >
                        <Box flex="1">
                            <AlertIcon color="orange.200" />
                            <AlertTitle display="inline" mr={2}>
                                Insufficient Credits
                            </AlertTitle>
                            <AlertDescription display="inline">
                                You need more credits to generate images with this model.
                            </AlertDescription>
                        </Box>
                        <Button
                            onClick={handleBuyCredits}
                            colorScheme="orange"
                            size="sm"
                            mt={{ base: 4, md: 0 }}
                            ml={{ md: 4 }}
                            bg="orange.600"
                            _hover={{ bg: 'orange.500' }}
                        >
                            Buy More Credits
                        </Button>
                    </Alert>
                )}

                {(!showCreditsWarning && remainingCredits <= 5) && (
                    <Alert
                        status="info"
                        variant="solid"
                        bg="blue.700"
                        color="white"
                        borderRadius="lg"
                        flexDirection={{ base: "column", md: "row" }}
                        alignItems={{ base: "stretch", md: "center" }}
                        justifyContent="space-between"
                        py={4}
                        px={6}
                    >
                        <Box flex="1">
                            <AlertIcon color="blue.200" />
                            <AlertTitle display="inline" mr={2}>
                                {remainingCredits} Credits Remaining
                            </AlertTitle>
                            <AlertDescription display="inline">
                                Your credits are running low. Consider buying more credits, to keep generating images.
                            </AlertDescription>
                        </Box>
                        <Button
                            onClick={handleBuyCredits}
                            colorScheme="blue"
                            size="sm"
                            mt={{ base: 4, md: 0 }}
                            ml={{ md: 4 }}
                            bg="blue.600"
                            _hover={{ bg: 'blue.500' }}
                        >
                            Buy More Credits
                        </Button>
                    </Alert>
                )}

                <Text as="p">
                    {category === 'custom' && (
                        <Text as="span" color="gray.300">
                            This is a custom model created by you. You can generate images using this model by
                            providing a prompt. You will not be able to generate images without including the
                            trigger word: <Text as="strong">{model?.trigger_word}</Text>.
                        </Text>
                    )}
                    {model?.description}
                </Text>

                {model?.link && (
                    <Button
                        as="a"
                        href={model.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        colorScheme="blue"
                        variant="solid"
                        size="md"
                        _hover={{
                            bg: 'blue.600',
                            color: 'white',
                            textDecoration: 'none'
                        }}
                    >
                        {model.link_text || 'Learn More'}
                    </Button>
                )}

                <Box
                    bg="gray.800"
                    p={6}
                    borderRadius="xl"
                    boxShadow="xl"
                >
                    <VStack spacing={6} align="stretch">
                        {promptSetting && (
                            <FormControl>
                                <FormLabel color="gray.300" textAlign="center">
                                    {promptSetting.description || ""}
                                    {(modelRequiredTriggerWord) && (
                                        <Text as="span" color="red.500"> (Must contain: {model.trigger_word})</Text>
                                    )}
                                </FormLabel>
                                <Box position="relative">
                                    <Textarea
                                        value={formData[promptSetting.key] || ""}
                                        onChange={(e) => handleInputChange(promptSetting.key, e.target.value)}
                                        placeholder="Enter your prompt here..."
                                        size="lg"
                                        minH="120px"
                                        bg="gray.700"
                                        color="white"
                                        borderColor="gray.600"
                                        _hover={{ borderColor: "blue.400" }}
                                        _focus={{ borderColor: "blue.400", boxShadow: "0 0 0 1px var(--chakra-colors-blue-400)" }}
                                        pr="100px"
                                    />
                                    <HStack
                                        position="absolute"
                                        top="8px"
                                        right="8px"
                                        spacing={2}
                                        zIndex={2}
                                    >
                                        {imageSetting && (
                                            <Tooltip label={imageSetting.description || "Upload Image"}>
                                                <Button
                                                    size="sm"
                                                    colorScheme="teal"
                                                    onClick={onOpen}
                                                    leftIcon={<PaperclipIcon size={16} />}
                                                    variant="ghost"
                                                    color="teal.300"
                                                    _hover={{ bg: "teal.800" }}
                                                >
                                                    {selectedImage ? "Change" : "Upload"}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        <Button
                                            size="sm"
                                            colorScheme="blue"
                                            onClick={handleSubmit}
                                            leftIcon={<ArrowUpIcon size={16} />}
                                            isDisabled={loading || isSubmitDisabled()}
                                            variant="ghost"
                                            _hover={{ bg: "blue.500" }}
                                            bg="transparent"
                                            color="blue.200"
                                        >
                                            Send
                                        </Button>
                                    </HStack>
                                </Box>
                            </FormControl>
                        )}

                        {selectedImage && (
                            <Box
                                p={4}
                                bg="gray.700"
                                borderRadius="md"
                            >
                                <Text mb={2} fontWeight="medium" color="gray.300">Selected Image:</Text>
                                <Box
                                    borderRadius="md"
                                    overflow="hidden"
                                    maxW="300px"
                                    mx="auto"
                                >
                                    <img
                                        src={selectedImage}
                                        alt="Selected"
                                        style={{
                                            maxHeight: "200px",
                                            width: "100%",
                                            objectFit: "cover"
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}

                        {latestResults.length  && (
                            <Box
                                p={4}
                                bg="gray.700"
                                borderRadius="md"
                            >
                                <Text mb={2} fontWeight="medium" color="gray.300">Results:</Text>
                                {latestResults.map((result, index) => (
                                    <Box key={`ir_${index}`}>
                                        <ImageResult
                                            key={index}
                                            index={index}
                                            result={result}
                                            category={category}
                                            onImageSelect={onImageSelect}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </VStack>
                </Box>
            </VStack>
            <Modal isOpen={isOpen} onClose={onClose} size="full">
                <ModalContent p={4} bg="gray.800" color="white">
                    <ModalCloseButton />
                    <GallerySelect onImageSelect={imageSelected} selectText="Import Image" />
                </ModalContent>
            </Modal>

            {includeSettings && (
                <>
                    <SettingsDrawer
                        isOpen={isSettingsOpen}
                        onClose={onSettingsClose}
                        settings={toolbarSettings}
                        formData={formData}
                        onSettingChange={handleInputChange}
                    />
                </>
            )}
        </>
    );
}

export default GenerateImage;