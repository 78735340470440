import API from "~/utils/api.ts";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export interface Product {
    public_id: string;
    image: string;
    image_url: string;
    no_background_image: string;
    status: string;
    prompt?: string;
}

export interface ProductGallery {
    public_id: string;
    name: string;
    images: Product[];
    is_collection: boolean;
}

interface Image {
    public_id: string;
    no_background_image_url: string;
    image_url: string;
    new_background_url: string | null | undefined;
    prompt?: string;
}

export interface Gallery {
    public_id: string;
    name: string;
    images: Image[];
    is_collection: boolean;
}

interface GalleryState {
    images: Image[];
    loading: boolean;
    error: string | null;
    image: Image | null;
    galleries: Gallery[];
    products: Product[];
    product: Product | null;
    gallery: ProductGallery | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: GalleryState = {
    images: [],
    loading: false,
    image: null,
    error: null,
    galleries: [],
    products: [],
    product: null,
    gallery: null,
    status: 'idle',
}

export const fetchProducts = createAsyncThunk<
    ProductGallery,
    void,
    { rejectValue: string }
>(
    'products/fetchProducts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await API.get('/cartario/products/');
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to fetch products');
        }
    }
);

export const fetchProduct = createAsyncThunk<
    Product,
    string,
    { rejectValue: string }
>(
    'products/fetchProduct',
    async (publicId, { rejectWithValue }) => {
        try {
            const response = await API.get(`/cartario/product/${publicId}/`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to fetch product');
        }
    }
);

export const removeProductBackground = createAsyncThunk(
    'images/removeBackgroundFromLayer',
    async ({ public_id, type }: { public_id: string, type: string }) => {
        const response = await API.post(`/cartario/product/${public_id}/remove-background/`, {
            session_type: type,
        });
        return response.data;
    }
);

export const fetchImages = createAsyncThunk(
    'gallery/fetchImages',
    async () => {
        const response = await API.get('/cartario/remove-background/');
        return response.data;
    }
)

export const fetchGalleries = createAsyncThunk(
    'gallery/fetchGalleries',
    async () => {
        const response = await API.get('/cartario/galleries/');
        return response.data;
    }
)

export const addGallery = createAsyncThunk(
    'gallery/addGallery',
    async (gallery: { name: string, description: string }) => {
        const response = await API.post('/cartario/galleries/', gallery);
        return response.data;
    }
)

export const addImage = createAsyncThunk(
    'gallery/addImage',
    async ({ formData, galleryId }: { formData: FormData, galleryId: string }) => {
        formData.append('galleryId', galleryId);
        const response = await API.post(`/cartario/gallery/${galleryId}/images/`, formData);
        return response.data;
    }
);

export const uploadImage = createAsyncThunk(
    'gallery/uploadImage',
    async (formData: FormData) => {
        const response = await API.post('/cartario/remove-background/', formData);
        return response.data;
    }
)


export const downloadImage = createAsyncThunk<string, { publicId: string, imageType: string }, { rejectValue: string }>(
    'images/downloadImage',
    async ({ publicId, imageType }, { rejectWithValue }) => {
        try {
            const response = await API.get(`/cartario/image/${publicId}/download/?type=${imageType}`, {
                responseType: 'blob',
            });
            const contentType = response.headers['content-type'];
            const extension = contentType.split('/')[1];

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${publicId}.${extension}`;
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);

            return 'Download succeeded';
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('Download failed');
        }
    }
);

export const deleteImage = createAsyncThunk<string, string, { rejectValue: string }>(
    'images/deleteImage',
    async (public_id, { rejectWithValue }) => {
        try {
            await API.delete(`/cartario/image/${public_id}/delete/`);
            return public_id;
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('Failed to delete image');
        }
    });

export const fetchImage = createAsyncThunk<Image, string, { rejectValue: string }>(
    'images/fetchImage',
    async (public_id, { rejectWithValue }) => {
        try {
            const response = await API.get(`/cartario/remove-background/${public_id}/`);
            return response.data;
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('Failed to fetch image');
        }
    });


export const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        resetProduct(state) {
            state.product = null;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchImages.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchImages.fulfilled, (state, action) => {
            state.loading = false;
            state.images = action.payload;
        });
        builder.addCase(fetchImages.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Failed to fetch images';
        })
        builder.addCase(uploadImage.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(uploadImage.fulfilled, (state, action) => {
            state.loading = false;
            state.images = [...state.images, action.payload];
        });
        builder.addCase(uploadImage.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Failed to upload image';
        });
        builder.addCase(downloadImage.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(downloadImage.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(downloadImage.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to download image";
        });
        builder.addCase(fetchImage.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchImage.fulfilled, (state, action) => {
            state.loading = false;
            state.image = action.payload;
        });
        builder.addCase(fetchImage.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to fetch image";
        });
        builder.addCase(fetchGalleries.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchGalleries.fulfilled, (state, action) => {
            state.loading = false;
            state.galleries = action.payload;
        });
        builder.addCase(fetchGalleries.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to fetch galleries";
        });
        builder.addCase(addGallery.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(addGallery.fulfilled, (state, action) => {
            state.loading = false;
            state.galleries = [...state.galleries, action.payload];
        });
        builder.addCase(addGallery.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to add gallery";
        });
        builder.addCase(addImage.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(addImage.fulfilled, (state, action) => {
            state.loading = false;
            state.galleries = state.galleries.map(gallery =>
                gallery.public_id === action.payload.public_id ? action.payload : gallery
            );
        });
        builder.addCase(addImage.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to add image";
        });
        builder.addCase(deleteImage.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteImage.fulfilled, (state, action) => {
            state.loading = false;
            state.images = state.images.filter(image => image.public_id !== action.payload);
            state.products = state.products.filter(product => product.public_id !== action.payload);
            if(state.gallery && state.gallery.images) {
                state.gallery.images = state.gallery.images.filter(image => image.public_id !== action.payload);
            }
        });
        builder.addCase(deleteImage.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to delete image";
        });
        builder.addCase(fetchProducts.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.products = action.payload.images;
            state.gallery = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(fetchProduct.pending, (state) => {
            state.status = 'loading';
            state.loading = true;
        });
        builder.addCase(fetchProduct.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.product = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchProduct.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(removeProductBackground.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(removeProductBackground.fulfilled, (state, action) => {
            state.loading = false;
            state.product = action.payload;
        });
        builder.addCase(removeProductBackground.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const {resetProduct} = gallerySlice.actions;
export default gallerySlice.reducer;
