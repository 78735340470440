import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '~/store';
import {
    getImage,
    setActiveLayer
} from '~/store/imageSlice.ts';
import {
    useToast,
    Box,
    Text,
    Spinner,
} from '@chakra-ui/react';

import {Center} from "@chakra-ui/icons";
import ModifyImageAdvanced from "~/apps/backend/components/ModifyImageAdvanced.tsx";
import ModifyImageWizard from "~/apps/backend/components/ModifyImageWizard.tsx";
import ModifyImageModals from "~/apps/backend/components/modals/ModifyImageModals.tsx";

interface ModifyImageProps {
    publicId: string | undefined | null;
    editorStyle: string;
}

const ModifyImage = ({publicId, editorStyle}: ModifyImageProps) => {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();

    const image = useSelector((state: RootState) => state.images.selectedImage);
    const loading = useSelector((state: RootState) => state.images.loading);
    const {
        activeLayer
    } = useSelector((state: RootState) => state.images.modifyImageSettings);

    const error_message = useSelector((state: RootState) => state.images.error_message);


    useEffect(() => {

        if (!publicId) {
            navigate('/c-panel/modify-image');
            return;
        }

        dispatch(getImage(publicId))
    }, [publicId]);

    useEffect(() => {
        if (error_message && error_message !== '') {
            toast({
                title: "Failed to fetch image",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }, [error_message]);

    useEffect(() => {
        if (activeLayer) {
            // Check if the active layer still exists in the current image
            const layerExists = image?.layers?.find(layer => layer.public_id === activeLayer.public_id);
            if (!layerExists) {
                dispatch(setActiveLayer(null));
            }
        }
    }, [image, activeLayer]);

    return (
        <Box p={4} bg="gray.800" minH="100vh" minW="1024px">
            {loading && (
                <Center
                    position="fixed"
                    inset={0}
                    bg="blackAlpha.700"
                    backdropFilter="blur(8px)"
                    zIndex={9999}
                    flexDirection="column"
                    gap={4}
                >
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.600"
                        color="primary.0"
                        size="xl"
                    />
                    <Text color="primary.0" fontSize="xl">
                        Loading...
                    </Text>
                </Center>
            )}

            {editorStyle === 'wizard' && (
                <ModifyImageWizard />
            )}

            {editorStyle === 'advanced'  && (
                <ModifyImageAdvanced />
            )}

            <ModifyImageModals  />
        </Box>
    )
}

export default ModifyImage;