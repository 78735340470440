// hooks/useLayerManagement.ts
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import {
    addImageEffect,
    addLayer, disableTool, enableTool,
    mergeLayers, modifyLayer,
    removeBackgroundFromLayer, removeLayer,
    resetLayerPositions,
    setActiveLayer, setLayers
} from '~/store/imageSlice';
import { AppDispatch, RootState } from '~/store';
import { Layer } from '~/utils/types';
import {downloadImage} from "~/store/gallerySlice.ts";

interface LayerInput {
    type: string;
    value: string;
    changeActiveLayer: boolean;
}

export const useLayerManagement = () => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();

    const loading = useSelector((state: RootState) => state.images.loading);
    const image = useSelector((state: RootState) => state.images.selectedImage);
    const {
        activeLayer,
        isMerged,
        hasMask,
        enhancedLighting,
        enhancedShadows,
    } = useSelector((state: RootState) => state.images.modifyImageSettings);

    const dispatchActiveLayer = (layer: Layer | null) => {
        dispatch(setActiveLayer(layer));
    };

    const handleAddLayer = async ({ type, value, changeActiveLayer=false }: LayerInput) => {
        if (!image || loading) {
            return;
        }

        let zIndex = 10 - image.layers.length;

        if(type === 'mask') {
            zIndex += 100;
        }

        const layer = {
            type,
            value,
            x: 0,
            y: 0,
            style: {},
            status: 'active',
            z_index: zIndex,
            width: 0,
            height: 0,
            other_values: {}
        };

        // First deactivate current layer
        dispatchActiveLayer(null);


        try {
            const response = await dispatch(addLayer({
                public_id: image.public_id,
                layer
            })).unwrap();

            if(changeActiveLayer) {
                // Set the newly added layer as active using the response from the server
                dispatchActiveLayer(response);
            }
            toast({
                title: "Layer added",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            console.error(error);
            toast({
                title: "Failed to add layer",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const removeBackground = (type: string, layer: Layer | null) => {
        if(!layer) {
            toast({
                title: "No layer selected",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const payload = {
            public_id: layer.public_id || '',
            type: type,
        }
        dispatch(removeBackgroundFromLayer(payload)).unwrap().then((response) => {
            console.log(response);
            toast({
                title: "Background removed",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }).catch(() => {
            toast({
                title: "Failed to remove background",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    const handleMergeLayers = async (isProduct: boolean = false) => {

        if(!isProduct) {
            if(!confirm('Are you sure you want to merge layers?')) {
                return;
            }
        }

        if(!image || loading) {
            return;
        }

        await dispatch(mergeLayers({
            public_id: image.public_id,
            layers: image.layers
        })).unwrap().then(() => {
            toast({
                title: "Layers merged",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

            if(!isProduct) {
                location.reload();
            }

        }).catch((e) => {
            console.log(e);
            toast({
                title: "Failed to merge layers",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    const resetLayers = () => {
        dispatch(resetLayerPositions())
    }

    const alterEffect = (effect: string) => {
        if (!image || image.layers.length === 0) {
            return;
        }
        const public_id = image.layers[0].public_id;
        if(typeof public_id === 'undefined') {
            return;
        }
        if(effect === 'original') {
            if(!confirm('Are you sure you want to reset back to original?')) {
                return;
            }
        }
        dispatch(addImageEffect({public_id: public_id, effect: effect})).unwrap().then(() => {
            toast({
                title: "Effect added",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            if(effect === 'original') {
                // location.reload
                location.reload();
            }
        }).catch(() => {
            toast({
                title: "Failed to add effect",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    }

    const handleDownload = async () => {
        console.log('Download image');
        if (!image) {
            return;
        }
        console.log(image);
        const image_puid = image.layers[0].public_id;
        if(!image_puid) {
            return;
        }

        dispatch(downloadImage({
            publicId: image_puid,
            imageType: 'layer',
        })).unwrap().then(() => {
            toast({
                title: "Image downloaded",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }).catch(() => {
            toast({
                title: "Failed to download image",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });

    }

    const deleteLayer = async (public_id: string | undefined | null) => {
        if(!public_id) {
            toast({
                title: "No layer selected",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        if(!confirm('Are you sure you want to delete this layer, this action can not be undone?')) {
            return;
        }

        try {
            // Clear active layer if we're deleting it
            if (activeLayer?.public_id === public_id) {
                dispatchActiveLayer(null);
            }

            await dispatch(removeLayer({public_id: public_id})).unwrap();

            toast({
                title: "Layer deleted",
                status: "success",
                duration: 3000,
                isClosable: true,
            });

        } catch (error) {
            toast({
                title: "Failed to delete layer",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    }

    const cloneLayer = async (layerId: string) => {
        if (!image || loading) {
            return;
        }

        // Find the layer to clone
        const layerToClone = image.layers.find(layer => layer.public_id === layerId);
        if (!layerToClone) {
            return;
        }

        // Create new layer object with same properties
        const newLayer: Layer = {
            type: layerToClone.type,
            value: layerToClone.value,
            x: layerToClone.x,
            y: layerToClone.y,
            style: { ...layerToClone.style },
            status: 'active',
            z_index: layerToClone.z_index,
            width: layerToClone.width,
            height: layerToClone.height,
            other_values: { ...layerToClone.other_values }
        };

        try {
            // First deactivate current layer
            dispatchActiveLayer(null);

            const response = await dispatch(addLayer({
                public_id: image.public_id,
                layer: newLayer
            })).unwrap();

            // Set the newly cloned layer as active
            if (response.layers && response.layers.length > 0) {
                const newLayer = response.layers[response.layers.length - 1];
                dispatchActiveLayer(newLayer);
            }

            toast({
                title: "Layer cloned successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Failed to clone layer",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const setLayerIndexes = (layers: Layer[]) => {
        dispatch(setLayers(layers));
    }
    const updateLayerPosition = (layer: Layer, params: any) => {
        dispatch(modifyLayer({
            params: params,
            layer: layer,
        }));
    }

    const updateLayer = (params: any) => {
        if (!activeLayer) return;

        const updatedLayer = {
            ...activeLayer,
            ...params
        };

        dispatch(modifyLayer({
            params: params,
            layer: activeLayer,
        }));
        dispatchActiveLayer(updatedLayer);
    };

    const handleToolToggle = (toolName: string) => {
        if(!image?.layers[0]) {
            return;
        }
        console.log(activeLayer);
        if (!activeLayer) {
            // set default active layer
            dispatchActiveLayer(image?.layers[0]);
        }

        const tool = activeLayer?.tools?.find((tool) => tool.name === toolName);

        if(!tool) {
            return;
        }

        if(!tool.isActive) {
            dispatch(
                enableTool(toolName)
            )
        } else {
            dispatch(
                disableTool(toolName)
            )
        }
    }

    return {
        removeBackground,
        handleAddLayer,
        handleMergeLayers,
        resetLayers,
        alterEffect,
        handleDownload,
        cloneLayer,
        deleteLayer,
        updateLayer,
        dispatchActiveLayer,
        handleToolToggle,
        updateLayerPosition,
        setLayerIndexes,
        activeLayer,
        loading,
        isMerged,
        hasMask,
        enhancedLighting,
        enhancedShadows,
        image
    };
};