import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button, Flex,
    HStack,
    IconButton,
    Text,
    Tooltip,
    VStack
} from "@chakra-ui/react";
import {HiRefresh} from "react-icons/hi";
import {FaArrowAltCircleUp, FaImage, FaMagic, FaMask, FaSave} from "react-icons/fa";
import {LuCloudFog} from "react-icons/lu";
import {BiRefresh} from "react-icons/bi";
import {MdMerge} from "react-icons/md";
import {DeleteIcon} from "@chakra-ui/icons";
import ModifyImageLayers from "~/apps/backend/components/ModifyImageLayers.tsx";
import {useImageModal} from "~/hooks/useImageModal.ts";
import {useLayerManagement} from "~/hooks/useLayerManagement.ts";
import DraggableLayer from "~/apps/backend/components/DraggableLayer.tsx";

const ModifyImageAdvanced = () => {
    const saveModal = useImageModal('saveModal');
    const maskModal = useImageModal('maskModal');
    const galleryModal = useImageModal('galleryModal');

    const {
        handleAddLayer,
        image,
        isMerged,
        hasMask,
        enhancedLighting,
        enhancedShadows,
        resetLayers,
        handleMergeLayers,
        alterEffect,
        handleDownload
    } = useLayerManagement();


    return (
        <>
            <Flex>
                <Box flex="1">
                    <ModifyImageLayers />
                </Box>
                <Box w="300px" bg="gray.700" p={4}>
                    {(!isMerged && !hasMask) && (
                        <Text mb={4}>
                            Before you can save / color correct you must first merge all layers.
                        </Text>
                    )}

                    {hasMask && (
                        <Alert>
                            <AlertIcon />
                            <AlertDescription color={'blue.400'}>
                                You must finish adding your mask and save the image before you select other layers.
                            </AlertDescription>
                        </Alert>
                    )}

                    <VStack spacing={4}>
                        <HStack spacing={4}>

                            {(enhancedLighting || enhancedShadows) && (
                                <Tooltip label="Reset Color & Shadows">
                                    <IconButton
                                        aria-label="Reset Color & Shadows"
                                        icon={<HiRefresh />}
                                        size="lg"
                                        variant="ghost"
                                        color="primary.0"
                                        onClick={() => alterEffect('reset')}
                                        _hover={{ opacity: 0.8 }}
                                        transition="opacity 0.2s"
                                    />
                                </Tooltip>
                            )}

                            {(!enhancedLighting && isMerged) && (
                                <>
                                    <Tooltip label="Auto Correct Colors">
                                        <IconButton
                                            aria-label="Auto Correct Colors"
                                            icon={<FaMagic />}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            onClick={() => alterEffect('lighting')}
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                        />
                                    </Tooltip>
                                </>
                            )}
                            {(!enhancedShadows && isMerged) && (
                                <>
                                    <Tooltip label="Auto Correct Shadows">
                                        <IconButton
                                            aria-label="Auto Correct Shadows"
                                            icon={<LuCloudFog />}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                            onClick={() => alterEffect('shadows')}
                                        />
                                    </Tooltip>
                                </>
                            )}

                            {(!isMerged && !hasMask) && (
                                <>
                                    <Tooltip label="Add Mask Layer" aria-label="Add Mark Layer Tooltip">
                                        <IconButton
                                            icon={<FaMask />}
                                            aria-label="Add Mask Layer"
                                            onClick={() => handleAddLayer({ type: 'mask', value: '', changeActiveLayer:true })}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                        />
                                    </Tooltip>
                                    <Tooltip label="Add Image Layer" aria-label="Add Image Layer Tooltip">
                                        <IconButton
                                            icon={<FaImage />}
                                            aria-label="Add Image Layer"
                                            onClick={() => galleryModal.onOpen()}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                        />
                                    </Tooltip>
                                    <Tooltip label="Reset Positions" aria-label="Reset Positions">
                                        <IconButton
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                            icon={<BiRefresh />} aria-label="Reset Positions" onClick={() => resetLayers()} />
                                    </Tooltip>
                                    {image && image.layers?.length > 1 && (
                                        <Tooltip label="Merge Layers" aria-label="Merge Layers">
                                            <IconButton
                                                size="lg"
                                                variant="ghost"
                                                color="primary.0"
                                                _hover={{ opacity: 0.8 }}
                                                transition="opacity 0.2s"
                                                icon={<MdMerge />} aria-label="Merge Layers" onClick={() => handleMergeLayers()} />
                                        </Tooltip>
                                    )}
                                </>
                            )}

                            {(image && image.layers.length === 1 && (image.layers[0].other_values?.backgroundRemoved || isMerged)) && (
                                <>
                                    <Tooltip label="Save New Image">
                                        <IconButton
                                            aria-label="Save New Image"
                                            icon={<FaSave />}
                                            size="lg"
                                            variant="ghost"
                                            color="primary.0"
                                            _hover={{ opacity: 0.8 }}
                                            transition="opacity 0.2s"
                                            onClick={saveModal.onOpen}
                                        />
                                    </Tooltip>
                                </>
                            )}

                            {hasMask && (
                                <Tooltip label="Activate Mask">
                                    <IconButton
                                        aria-label="Activate Mask"
                                        icon={<FaArrowAltCircleUp />}
                                        size="lg"
                                        variant="ghost"
                                        color="primary.0"
                                        _hover={{ opacity: 0.8 }}
                                        transition="opacity 0.2s"
                                        onClick={maskModal.onOpen}
                                    />
                                </Tooltip>
                            )}

                            <Tooltip label="Reset back to original">
                                <IconButton
                                    aria-label="Reset back to original"
                                    icon={<DeleteIcon />}
                                    size="lg"
                                    variant="ghost"
                                    color="primary.0"
                                    _hover={{ opacity: 0.8 }}
                                    transition="opacity 0.2s"
                                    onClick={() => alterEffect('original')}
                                />
                            </Tooltip>


                        </HStack>
                        {!isMerged && image && !hasMask && image.layers && image.layers.map((layer: any, index: number) => (
                            <DraggableLayer
                                key={layer.public_id}
                                layer={layer}
                                index={index}
                                layers={image.layers}
                            />
                        ))}
                        {(image && image.layers.length && !hasMask) && (
                            <Button
                                onClick={() => handleDownload()}
                                bg="gray.600"
                                color="primary.0"
                                _hover={{ bg: "gray.600", color: "white" }}
                            >
                                Download Image
                            </Button>
                        )}
                    </VStack>
                </Box>
            </Flex>
        </>

    );
}

export default ModifyImageAdvanced;