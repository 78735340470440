import {
    Box,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Modal,
    Text,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    useDisclosure,
    VStack,
    useToast,
    Heading
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "~/store";
import { useEffect, useState } from "react";
import { fetchUser, updatePassword, updateProfile, User } from "~/store/authSlice";

const Profile = () => {
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.auth.me);
    const [isEditing, setIsEditing] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [formData, setFormData] = useState<{firstName: string; lastName: string}>({
        firstName: '',
        lastName: ''
    });

    const [passwords, setPasswords] = useState({
        current: '',
        new: '',
        confirm: ''
    });

    const [errors, setErrors] = useState({
        current: '',
        new: '',
        confirm: ''
    });

    useEffect(() => {
        if (user) {
            setFormData({
                firstName: user.first_name,
                lastName: user.last_name
            });
        } else {
            dispatch(fetchUser());
        }
    }, [user]);

    const validatePassword = (password: string) => {
        if (password.length < 6) return 'Must be at least 6 characters';
        if (!/[A-Z]/.test(password)) return 'Must contain uppercase letter';
        if (!/[0-9]/.test(password)) return 'Must contain number';
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) return 'Must contain special character';
        return '';
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswords(prev => {
            const newPasswords = { ...prev, [name]: value };

            // Update errors based on new password state
            if (name === 'new' || name === 'confirm') {
                setErrors(prev => ({
                    ...prev,
                    new: validatePassword(newPasswords.new),
                    confirm: newPasswords.new !== newPasswords.confirm ? 'Passwords must match' : ''
                }));
            }

            return newPasswords;
        });

        if (name === 'new') {
            setErrors(prev => ({ ...prev, new: validatePassword(value) }));
        }
        if (name === 'confirm') {
            setErrors(prev => ({
                ...prev,
                confirm: value !== passwords.new ? 'Passwords must match' : ''
            }));
        }
    };

    const handleSave = () => {
        try {
            dispatch(updateProfile({
                first_name: formData.firstName,
                last_name: formData.lastName
            }));
            setIsEditing(false);
            toast({
                title: 'Success',
                description: 'Profile updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update profile',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    const handlePasswordSave = () => {
        const newPasswordError = validatePassword(passwords.new);
        const matchError = passwords.new !== passwords.confirm ? 'Passwords must match' : '';

        if (newPasswordError || matchError || !passwords.current) {
            setErrors({
                current: !passwords.current ? 'Required' : '',
                new: newPasswordError,
                confirm: matchError
            });
            return;
        }

        try {
            dispatch(updatePassword({
                current_password: passwords.current,
                new_password: passwords.new
            }));
            onClose();
            setPasswords({ current: '', new: '', confirm: '' });
            setErrors({ current: '', new: '', confirm: '' });
            toast({
                title: 'Success',
                description: 'Password updated successfully',
                status: 'success',
                duration: 5000,
                isClosable: true
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to update password',
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    const CreditsBox = ({user}: {user: User | null}) => (
        <Box bg="gray.800" p={6} borderRadius="lg" boxShadow="lg" mb={6} maxW="md" mx="auto">
            <Heading size="md" color="white" mb={4}>Available Credits</Heading>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Text color="gray.400">Base Credits</Text>
                    <Text color="white" fontSize="xl">{user?.credits ?? 0}</Text>
                </Box>
                <Box>
                    <Text color="gray.400">Bonus Credits</Text>
                    <Text color="white" fontSize="xl">{user?.extra_credits ?? 0}</Text>
                </Box>
                <Box>
                    <Text color="gray.400">Total Credits</Text>
                    <Text color="white" fontSize="xl">{(user?.credits ?? 0) + (user?.extra_credits ?? 0)}</Text>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box bg="gray.900" minH="100vh" py={12}>
            <CreditsBox user={user} />
            <Box maxW="md" mx="auto" bg="gray.800" p={8} borderRadius="lg" boxShadow="lg">
                <VStack spacing={6}>
                    <Tooltip label="Contact support to change email address" placement="top">
                        <FormControl>
                            <FormLabel color="white">Email</FormLabel>
                            <Input
                                value={user?.email ?? ''}
                                isDisabled
                                bg="gray.700"
                                color="white"
                                _disabled={{ opacity: 0.7, cursor: 'not-allowed' }}
                            />
                        </FormControl>
                    </Tooltip>

                    <FormControl>
                        <FormLabel color="white">First Name</FormLabel>
                        <Input
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            isDisabled={!isEditing}
                            bg="gray.700"
                            color="white"
                            _disabled={{ opacity: 0.7 }}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel color="white">Last Name</FormLabel>
                        <Input
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            isDisabled={!isEditing}
                            bg="gray.700"
                            color="white"
                            _disabled={{ opacity: 0.7 }}
                        />
                    </FormControl>

                    <Box w="100%" display="flex" gap={4}>
                        {isEditing ? (
                            <>
                                <Button colorScheme="blue" onClick={handleSave} flex={1}>
                                    Save Changes
                                </Button>
                                <Button onClick={() => setIsEditing(false)} flex={1} variant="outline" color="primary.0" _hover={{ bg: "gray.700" }}>
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <Button colorScheme="blue" onClick={() => setIsEditing(true)} w="100%">
                                Edit Profile
                            </Button>
                        )}
                    </Box>

                    {!user?.is_google_user && (
                        <Button variant="outline" color="primary.0" onClick={onOpen} w="100%" _hover={{ bg: "gray.700" }}>
                            Change Password
                        </Button>
                    )}
                </VStack>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent bg="gray.800">
                        <ModalHeader color="white">Change Password</ModalHeader>
                        <ModalCloseButton color="white" />
                        <ModalBody>
                            <VStack spacing={4}>
                                <FormControl isInvalid={!!errors.current}>
                                    <FormLabel color="white">Current Password</FormLabel>
                                    <Input
                                        name="current"
                                        type="password"
                                        value={passwords.current}
                                        onChange={handlePasswordChange}
                                        bg="gray.700"
                                        color="white"
                                    />
                                    <FormErrorMessage>{errors.current}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!errors.new}>
                                    <FormLabel color="white">New Password</FormLabel>
                                    <Input
                                        name="new"
                                        type="password"
                                        value={passwords.new}
                                        onChange={handlePasswordChange}
                                        bg="gray.700"
                                        color="white"
                                    />
                                    <FormErrorMessage>{errors.new}</FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!!errors.confirm}>
                                    <FormLabel color="white">Confirm New Password</FormLabel>
                                    <Input
                                        name="confirm"
                                        type="password"
                                        value={passwords.confirm}
                                        onChange={handlePasswordChange}
                                        bg="gray.700"
                                        color="white"
                                    />
                                    <FormErrorMessage>{errors.confirm}</FormErrorMessage>
                                </FormControl>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                colorScheme="blue"
                                onClick={handlePasswordSave}
                                isDisabled={!!errors.new || !!errors.confirm || !passwords.current}
                            >
                                Update Password
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Box>
    );
};

export default Profile;