import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store';
import {closeModal, ImageState, openModal} from "~/store/imageSlice.ts";

export const useImageModal = (modalKey: keyof ImageState['activeModals']) => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state: RootState) => state.images.activeModals[modalKey]);

    return {
        isOpen,
        onOpen: () => dispatch(openModal(modalKey)),
        onClose: () => dispatch(closeModal(modalKey)),
    };
};