import ModifyImageLayers from "~/apps/backend/components/ModifyImageLayers.tsx";
import {
    Box,
    Button,
    Flex,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps, VStack,
    Text,
} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {useImageModal} from "~/hooks/useImageModal.ts";
import {useLayerManagement} from "~/hooks/useLayerManagement.ts";
import ModifyImageToolbar, {ToolbarButton} from "~/apps/backend/components/tools/ModifyImageToolbar.tsx";
import {Image, DeleteIcon, TerminalIcon} from "lucide-react";
import {useNavigate} from "react-router-dom";


interface StepConfig {
    title: string;
    description: string;
    actionLabel?: string;
}

const steps: StepConfig[] = [
    {
        title: 'Remove Background',
        description: 'Remove the original background',
        actionLabel: 'Remove Background'
    },
    {
        title: 'New Background',
        description: 'Choose a new background image'
    },
    {
        title: 'Modify',
        description: 'Modify the image'
    },
];

const ModifyImageWizard = () => {
    let defaultStep = 0;

    const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
        index: defaultStep,
        count: steps.length
    });
    const galleryModal = useImageModal('galleryModal');
    const generateModal = useImageModal('generateModal');
    const { handleMergeLayers, dispatchActiveLayer, deleteLayer, image } = useLayerManagement();
    const [hasRemovedBackground, setHasRemovedBackground] = useState(false);
    const [additionalButtons, setAdditionalButtons] = useState<ToolbarButton[]>([]);    // useEffect(() => {
    const [isSaving, setIsSaving] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // onload set active layer to null and reset steps
        dispatchActiveLayer(null);
        setActiveStep(0);
    }, []);

    useEffect(() => {
        setAdditionalButtons([])
        if(activeStep < 2) {
            dispatchActiveLayer(null);
        }
        if(activeStep === 1) {
            if((image?.layers?.length || 0) < 2) {
                setAdditionalButtons([
                    {
                        icon: Image,
                        label: "Open Gallery",
                        onClick: () => galleryModal.onOpen(),
                        position: 'left',
                        show: true,
                    },
                    {
                        icon: TerminalIcon,
                        label: "Generate Background",
                        onClick: () => generateModal.onOpen(),
                        position: 'left',
                        show: true,
                    }
                ]);
            } else {
                setAdditionalButtons([
                    {
                        icon: DeleteIcon,
                        label: "Delete Background Image",
                        onClick: () => deleteLayer(image?.layers[1]?.public_id),
                        position: 'left',
                        show: true,
                    }
                ]);
            }
        }
        if(activeStep == 2 && image?.layers?.length) {
            dispatchActiveLayer(image.layers[0]);
        }
    }, [activeStep, image]);

    useEffect(() => {
        const hmrck = image?.layers[0]?.other_values?.backgroundRemoved || false;
        setHasRemovedBackground( hmrck !== false );

        if(!hasLoaded && image) {
            if(hmrck) {
                setActiveStep(1);
            }
            if(image.layers.length > 1) {
                setActiveStep(2);
            }
            setHasLoaded(true);
        }

    }, [image]);

    const handleSave = async () => {
        if(isSaving) {
            return;
        }
        setIsSaving(true);
       try {
           await handleMergeLayers(true);
           setIsSaving(false);
           navigate('/c-panel/products');
           console.log('saved');
       } catch (error) {
           console.error(error);
           setIsSaving(false);
       }
    };
    return (
        <VStack spacing={8} width="100%" pt={50}>
            {/* Stepper */}
            <Box width="100%" maxW={1000}>
                <Stepper size='lg' index={activeStep} mb={8}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink='0'>
                                <StepTitle>{step.title}</StepTitle>
                                <StepDescription>{step.description}</StepDescription>
                            </Box>

                            <StepSeparator />
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <Flex width="100%" justify="center" maxW="600px" mb={-34}>
                <ModifyImageToolbar
                    showRemoveBackground={activeStep < 1}
                    additionalButtons={additionalButtons}
                />
            </Flex>

            {/* Canvas - Always visible */}
            <Box width="100%">
                <ModifyImageLayers />
            </Box>

            {/* Current Step Action */}
            <Box maxW={600}>
                {activeStep == 0 && (
                    <>
                        {hasRemovedBackground ? (
                            <>
                                <Text as={'p'} mb="1.3em">
                                    Step 1: Remove Background - Not happy with the result? Click the
                                    remove background icon on the image toolbar to try again.
                                </Text>
                                <Text as={'p'} mb="1.3em">
                                    Don't worry about the quality of the background removal too much
                                    as during step 3 you will be able to manually adjust the image
                                    and remove any missed parts.
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text as={'p'} mb="1.3em">
                                    Step 1: Remove Background - click the remove background icon on the
                                    image toolbar to remove the background.
                                </Text>
                            </>
                        )}
                    </>
                )}

                {(image && image.layers && activeStep == 1) && (
                    <>
                        {image.layers.length > 1 ? (
                            <>
                                <Text as={'p'} mb="1.3em">
                                    Step 2: New Background - click the delete icon on the image toolbar to
                                    remove the current background image. At which point you will be able
                                    to select a new one.
                                </Text>
                                <Text as={'p'} mb="1.3em">
                                    If your image is too big on the new background, you can resize it in
                                    step 3.
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text as={'p'} mb="1.3em">
                                    Step 2: New Background - click the gallery icon on the image toolbar to
                                    choose a new background image.
                                </Text>
                                <Text as={'p'} mb="1.3em">
                                    Alternatively you can generate a new background image by clicking the
                                    prompt icon.
                                </Text>
                            </>
                        )}
                    </>
                )}

                {activeStep == 2 && (
                    <>
                        <Text as={'p'} mb="1.3em">
                            Step 3: Modify - click the tools on the image toolbar to modify the image. Once you're
                            happy with the result, click the finish button.
                        </Text>
                    </>
                )}
            </Box>


            {/* Navigation */}
            <Flex width="100%" justify="space-between" maxW={600}>
                <Button
                    isDisabled={activeStep === 0}
                    onClick={goToPrevious}
                    variant="outline"
                    color="primary.0"
                >
                    Previous
                </Button>

                {activeStep < steps.length -1 ? (
                    <Button
                        isDisabled={(activeStep === 1 && (image?.layers?.length || 0) < 2) || (activeStep === 0 && !hasRemovedBackground)}
                        onClick={goToNext}
                        colorScheme="blue"
                    >
                        {activeStep === 1 ? 'Modify' : 'Continue'}
                    </Button>
                ): (
                    <Button
                        isDisabled={isSaving}
                        onClick={() => handleSave()}
                        colorScheme="blue"
                    >
                        Save
                    </Button>
                )}
            </Flex>
        </VStack>
    );
}

export default ModifyImageWizard;