import {AppDispatch, RootState} from "~/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchProducts, Product, resetProduct} from "~/store/gallerySlice.ts";
import GallerySelect from "~/apps/backend/components/GallerySelect.tsx";
import {GalleryImage} from "~/utils/types.ts";
import {Box} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";

const products = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {gallery} = useSelector((state: RootState) => state.gallery);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(resetProduct());
    }, []);

    const onImageSelect = (image: GalleryImage | Product) => {
        navigate(`/c-panel/product/${image.public_id}/change-background`);
    }

    return (
        <Box p={4} bg="gray.800" w="100%" minH="100vh" display="flex" flexDirection="column">
            <GallerySelect
                    showManageOptions={true}
                    onImageSelect={onImageSelect}
                    selectText="Change Background"
                    defaultGallery={gallery}
                />
        </Box>
    )
}

export default products;