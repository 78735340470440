import {useParams} from "react-router-dom";
import {Box} from "@chakra-ui/react";
import ModifyImage from "~/apps/backend/components/ModifyImage.tsx";

const ProductChangeBackground = () => {
    const { public_id } = useParams<{ public_id: string }>();

    return (
        <Box minH="100ch" bgColor="gray.800">
            <ModifyImage publicId={public_id} editorStyle="wizard" />
        </Box>
    )
};

export default ProductChangeBackground;