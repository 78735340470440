import { Box, Divider, Heading, Icon, Link, VStack } from "@chakra-ui/react";
import {
    FaUser,
    FaCreditCard,
    FaProjectDiagram,
    FaSignOutAlt,
    FaChalkboard,
    FaImage,
    FaHandsHelping, FaProductHunt
} from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
    const location = useLocation();

    // Sidebar links with paths to check active state
    const sidebarLinks = [
        { label: "My Models", icon: FaProjectDiagram, href: "/c-panel/models" },
        { label: "My Products", icon: FaProductHunt, href: "/c-panel/products" },
        { label: "Generate Images", icon: FaChalkboard, href: "/c-panel/generate-images" },
        { label: "My Images", icon: FaImage, href: "/c-panel/my-images" },
        { label: "Tutorials", icon: FaHandsHelping, href: "/c-panel/tutorials" },
    ];

    const bottomLinks = [
        { label: "Profile", icon: FaUser, href: "/c-panel/profile" },
        { label: "Billing", icon: FaCreditCard, href: "/c-panel/billing" },
        { label: "Support", icon: FaHandsHelping, href: "/c-panel/support" },
        { label: "Logout", icon: FaSignOutAlt, href: "/logout" },
    ];

    return (
        <Box
            w="250px"
            p="5"
            bg="brand.800"
            color="primary.0"
            height="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            overflowY="auto"
        >
            <VStack align="stretch" spacing="4" flex="1" overflowY="auto">
                <Heading size="md">CARTARIO</Heading>
                <Divider borderColor="primary.0" />

                {/* Sidebar Links */}
                {sidebarLinks.map((link, index) => (
                    <Link
                        key={index}
                        display="flex"
                        alignItems="center"
                        href={link.href}
                        color={location.pathname === link.href ? "body.2" : "primary.0"} // Active link color from theme
                        fontWeight={location.pathname === link.href ? "bold" : "normal"} // Bold for active link
                        _hover={{ color: "body.2" }} // Hover effect with active link color
                    >
                        <Icon as={link.icon} mr="2" color="primary.0" /> {/* Icon color from theme */}
                        {link.label}
                    </Link>
                ))}
            </VStack>

            <VStack align="stretch" spacing="4" mt="4">
                <Divider borderColor="primary.0" />

                {bottomLinks.map((link, index) => (
                    <Link
                        key={index}
                        display="flex"
                        alignItems="center"
                        href={link.href}
                        color={location.pathname === link.href ? "body.2" : "primary.0"}
                        fontWeight={location.pathname === link.href ? "bold" : "normal"}
                        _hover={{ color: "body.2" }}
                    >
                        <Icon as={link.icon} mr="2" color="primary.0" />
                        {link.label}
                    </Link>
                ))}
            </VStack>
        </Box>
    );
};

export default Sidebar;
