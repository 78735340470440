import React, { useState } from 'react';
import {
    IconButton,
    Tooltip,
    HStack,
    Divider,
    Box,
    Spacer
} from "@chakra-ui/react";
import {
    Eraser,
    Maximize2,
    ImageOff,
    RotateCcw,
    LucideProps
} from 'lucide-react';
import { useLayerManagement } from "~/hooks/useLayerManagement.ts";

export interface ToolbarButton {
    icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>;
    label: string;
    onClick: () => void;
    disabled?: boolean;
    position?: 'left' | 'right';
    show?: boolean;
}

interface ModifyImageToolbarProps {
    additionalButtons?: ToolbarButton[];
    showEraser?: boolean;
    showResize?: boolean;
    showRemoveBackground?: boolean;
    showReset?: boolean;
}

const ModifyImageToolbar: React.FC<ModifyImageToolbarProps> = ({
                                                                   additionalButtons = [],
                                                                   showEraser = true,
                                                                   showResize = true,
                                                                   showRemoveBackground = true,
                                                                   showReset = true,
                                                               }) => {
    const [backgroundRmAttempts, setBackgroundRmAttempts] = useState(0);
    const { activeLayer, image, alterEffect, handleToolToggle, removeBackground } = useLayerManagement();

    let backgroundRemoveLayer = activeLayer;
    if (!activeLayer && image?.layers && image.layers.length > 0) {
        backgroundRemoveLayer = image.layers[0];
    }

    const removeBackgroundTypes = ['basic', 'complex'];

    const rmBackground = () => {
        if (backgroundRmAttempts >= removeBackgroundTypes.length) return;
        removeBackground(removeBackgroundTypes[backgroundRmAttempts], backgroundRemoveLayer);
        setBackgroundRmAttempts(backgroundRmAttempts + 1);
    };

    const handleResetImage = () => {
        alterEffect('original');
        setBackgroundRmAttempts(0);
    };


    const leftButtons = additionalButtons.filter(btn => btn.position !== 'right' && btn.show !== false);
    const rightButtons = additionalButtons.filter(btn => btn.position === 'right' && btn.show !== false);

    return (
        <Box
            width="full"
            py={2}
            px={4}
            bg="gray.900"
            boxShadow="sm"
            borderWidth={0}
        >
            <HStack spacing={4} align="center">
                {leftButtons.map((btn, index) => (
                    <Tooltip
                        key={index}
                        label={btn.label}
                        placement="top"
                        hasArrow
                        openDelay={400}
                    >
                        <IconButton
                            aria-label={btn.label}
                            icon={<btn.icon size={20} />}
                            onClick={btn.onClick}
                            variant="ghost"
                            _hover={{ bg: "blue.900" }}
                            color="primary.0"
                            size="md"
                            isDisabled={btn.disabled}
                        />
                    </Tooltip>
                ))}

                {activeLayer && (
                    <>
                        {showEraser && (
                            <Tooltip
                                label="Eraser Tool (E)"
                                placement="top"
                                hasArrow
                                openDelay={400}
                            >
                                <IconButton
                                    aria-label="Eraser Tool"
                                    icon={<Eraser size={20} />}
                                    onClick={() => handleToolToggle('eraser')}
                                    variant="ghost"
                                    color="primary.0"
                                    _hover={{ bg: "blue.900" }}
                                    size="md"
                                />
                            </Tooltip>
                        )}

                        {showResize && (
                            <Tooltip
                                label="Resize Tool (R)"
                                placement="top"
                                hasArrow
                                openDelay={400}
                            >
                                <IconButton
                                    aria-label="Resize Tool"
                                    icon={<Maximize2 size={20} />}
                                    onClick={() => handleToolToggle('resize')}
                                    variant="ghost"
                                    color="primary.0"
                                    _hover={{ bg: "blue.900" }}
                                    size="md"
                                />
                            </Tooltip>
                        )}

                        <Divider orientation="vertical" height="24px" />
                    </>
                )}

                {showRemoveBackground && (
                    <Tooltip
                        label={`Remove Background ${backgroundRmAttempts === 1 ? '(Enhanced)' : ''}`}
                        placement="top"
                        hasArrow
                        openDelay={400}
                    >
                        <IconButton
                            aria-label="Remove Background"
                            icon={<ImageOff size={20} />}
                            onClick={rmBackground}
                            variant="ghost"
                            color="primary.0"
                            _hover={{ bg: "blue.900" }}
                            size="md"
                            isDisabled={backgroundRmAttempts >= removeBackgroundTypes.length}
                        />
                    </Tooltip>
                )}

                <Spacer />

                {rightButtons.map((btn, index) => (
                    <Tooltip
                        key={`right-${index}`}
                        label={btn.label}
                        placement="top"
                        hasArrow
                        openDelay={400}
                    >
                        <IconButton
                            aria-label={btn.label}
                            icon={<btn.icon size={20} />}
                            onClick={btn.onClick}
                            variant="ghost"
                            _hover={{ bg: "blue.900" }}
                            size="md"
                            color="primary.0"
                            isDisabled={btn.disabled}
                        />
                    </Tooltip>
                ))}

                {showReset && (
                    <Tooltip
                        label="Reset Image"
                        placement="top"
                        hasArrow
                        openDelay={400}
                    >
                        <IconButton
                            aria-label="Reset Image"
                            icon={<RotateCcw size={20} />}
                            onClick={handleResetImage}
                            variant="ghost"
                            color="primary.0"
                            _hover={{ bg: "blue.900" }}
                            size="md"
                        />
                    </Tooltip>
                )}
            </HStack>
        </Box>
    );
};

export default ModifyImageToolbar;