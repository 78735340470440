import {
    Box, Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, Textarea, useToast,
    VStack
} from "@chakra-ui/react";
import GallerySelect from "~/apps/backend/components/GallerySelect.tsx";
import Gallery from "~/apps/backend/components/modals/Gallery.tsx";
import {
    activateMaskLayer,
    saveImage,
    setMaskPrompt
} from "~/store/imageSlice.ts";
import {AppDispatch, RootState} from "~/store";
import {useDispatch, useSelector} from "react-redux";
import {GalleryImage} from "~/utils/types.ts";
import {Product} from "~/store/gallerySlice.ts";
import {useNavigate} from "react-router-dom";
import { useImageModal } from '~/hooks/useImageModal';
import {useLayerManagement} from "~/hooks/useLayerManagement.ts";
import GenerateImage from "~/apps/backend/components/GenerateImage.tsx";



const ModifyImageModals = () => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const navigate = useNavigate();

    const { handleAddLayer, dispatchActiveLayer } = useLayerManagement();

    // Replace individual modal states with useImageModal hook
    const backgroundModal = useImageModal('galleryModal');
    const saveModal = useImageModal('saveModal');
    const maskModal = useImageModal('maskModal');
    const generateModal = useImageModal('generateModal');

    const {selectedImage, loading} = useSelector((state: RootState) => state.images);
    const {
        maskPrompt,
        activeLayer
    } = useSelector((state: RootState) => state.images.modifyImageSettings);

    const handleMaskActivation = () => {
        if (!maskPrompt.trim() || !activeLayer?.public_id) {
            toast({
                title: "Please enter a transformation instruction",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        dispatch(activateMaskLayer({
            public_id: activeLayer.public_id,
            prompt: maskPrompt.trim()
        })).unwrap()
            .then(() => {
                maskModal.onClose();
                dispatch(setMaskPrompt(''));
                dispatchActiveLayer(null);
                toast({
                    title: "Mask transformation applied",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                location.reload();
            })
            .catch(() => {
                toast({
                    title: "Failed to apply mask transformation",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            });
    };

    const handleSave = (gallery_public_id: string) => {
        const image_public_id = selectedImage?.public_id;
        if (!image_public_id) {
            return;
        }

        dispatch(saveImage({ image_public_id, gallery_public_id })).unwrap().then(() => {
            saveModal.onClose();
            navigate('/c-panel/my-images');
        }).catch(() => {
            toast({
                title: "Failed to save image",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        });
    };

    const imageSelected = (image: GalleryImage | Product) => {
        handleAddLayer({
            type: 'image',
            value: image.image_url,
            changeActiveLayer: false
        });
        backgroundModal.onClose();
    }

    const imageSelectedFromPrompt = (image: GalleryImage | Product) => {
        handleAddLayer({
            type: 'image',
            value: image.image_url,
            changeActiveLayer: false
        });
        generateModal.onClose();
    }


    return (
        <>
            <Modal isOpen={backgroundModal.isOpen} onClose={backgroundModal.onClose} size="full">
                <ModalContent p={4} bg="gray.800" color="primary.0">
                    <ModalCloseButton />
                    <GallerySelect
                        onImageSelect={imageSelected} selectText="Import Image" />
                </ModalContent>
            </Modal>
            <Modal isOpen={generateModal.isOpen} onClose={generateModal.onClose} size="full">
                <ModalContent p={4} bg="gray.800" color="primary.0">
                    <ModalCloseButton />

                    <GenerateImage public_id={'flux1.1'} category={'flux'} includeSettings={false}
                                   onImageSelect={imageSelectedFromPrompt} />
                </ModalContent>
            </Modal>

            <Modal isOpen={saveModal.isOpen} onClose={saveModal.onClose}>
                <ModalContent p={4} bg="gray.800" color="primary.0">
                    <ModalCloseButton />
                    <Box minH={250}>
                        <Text mb={4}>
                            Please select a gallery and the image will save to that gallery. If you have not created
                            a gallery yet, please create one first.
                        </Text>
                        <Text as="small">
                            Click 'Select Gallery'
                        </Text>
                        <Gallery
                            isVisible={saveModal.isOpen}
                            onClose={saveModal.onClose}
                            isSelect={true}
                            onListSelect={(publicId) => handleSave(publicId)}
                        />
                    </Box>
                </ModalContent>
            </Modal>

            <Modal isOpen={maskModal.isOpen} onClose={maskModal.onClose}>
                <ModalOverlay />
                <ModalContent bg="gray.800" color="primary.0">
                    <ModalHeader>Mask Transformation</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <Text>
                                Please specify how you would like to transform the masked area.
                                Your instruction should clearly describe the desired modification
                                (e.g., "Change the shirt color to deep purple" or "Add a subtle
                                texture to the masked region").
                            </Text>
                            <Textarea
                                placeholder="Enter your transformation instruction..."
                                size="md"
                                resize="vertical"
                                min-height="100px"
                                value={maskPrompt}
                                onChange={(e) => dispatch(setMaskPrompt(e.target.value))}
                            />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={handleMaskActivation}
                            isLoading={loading}
                        >
                            Transform
                        </Button>
                        <Button variant="ghost" color="primary.0" onClick={maskModal.onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ModifyImageModals;