import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import collectionReducer from './collectionSlice';
import imageReducer from './imageSlice';
import submissionReducer from './submissionSlice';
import popupReducer from './popupSlice';
import billingReducer from './billingSlice';
import galleryReducer from './gallerySlice.ts';
import modelReducer from './modelSlice.ts';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        collections: collectionReducer,
        images: imageReducer,
        submission: submissionReducer,
        popup: popupReducer,
        billing: billingReducer,
        gallery: galleryReducer,
        models: modelReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
