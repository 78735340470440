import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import API from '../utils/api';
import {Layer, ModifyImage, Stroke} from "~/utils/types.ts";


interface ModifyImageSettings {
    activeLayer: Layer | null;
    isMerged: boolean;
    enhancedShadows: boolean;
    enhancedLighting: boolean;
    maskPrompt: string;
    hasMask: boolean;
    defaultCanvasSize: number;
    defaultCanvasHeight: number;
}

interface ActiveModals {
    backgroundSelector: boolean;
    saveModal: boolean;
    maskModal: boolean;
    galleryModal: boolean;
    maskActivate: boolean;
    generateModal: boolean;
}

// Define the initial state
export interface ImageState {
    collection: any | null;
    images: any[];
    status: string;
    error_message: string | null;
    loading: boolean;
    selectedImage: ModifyImage | null;
    modifyImageSettings: ModifyImageSettings;
    activeModals: ActiveModals;
}

const initialState: ImageState = {
    collection: null,
    images: [],
    status: '',
    error_message: null,
    loading: false,
    selectedImage: null,
    modifyImageSettings: {
        activeLayer: null,
        isMerged: false,
        enhancedShadows: false,
        enhancedLighting: false,
        maskPrompt: '',
        hasMask: false,
        defaultCanvasSize: 600,
        defaultCanvasHeight: 600,
    },
    activeModals: {
        backgroundSelector: false,
        maskModal: false,
        galleryModal: false,
        saveModal: false,
        maskActivate: false,
        generateModal: false,
    },
};

// Async thunk to fetch image generation data (GET)
export const fetchImages = createAsyncThunk(
    'images/fetchImages',
    async (public_id: string) => {
        const response = await API.get(`/cartario/collection/${public_id}/generate-images/`);
        return response.data;
    }
);

// Async thunk to send prompt to generate images (POST)
export const sendPrompt = createAsyncThunk(
    'images/sendPrompt',
    async ({ public_id, settings }: { public_id: string, settings: object}) => {
        const response = await API.post(`/cartario/collection/${public_id}/generate-images/`, settings);
        return response.data;
    }
);

export const getImage = createAsyncThunk(
    'images/getImage',
    async (public_id: string) => {
        const response = await API.get(`/cartario/image/${public_id}/`);
        return response.data;
    }
);

export const addLayer = createAsyncThunk(
    'images/addLayer',
    async ({ public_id, layer }: { public_id: string, layer: Layer }) => {
        const response = await API.post(`/cartario/image/${public_id}/add-layer/`, layer);
        return response.data;
    }
);

export const activateMaskLayer = createAsyncThunk(
    'images/activateMaskLayer',
    async ({ public_id, prompt }: { public_id: string, prompt: string }) => {
        const response = await API.post(`/cartario/layer/${public_id}/activate-mask/`, {
            prompt: prompt
        });
        return response.data;
    }
);

interface UpdateMaskParams {
    public_id: string;
    maskData: string;
    strokes: Stroke[];
}

export const updateMaskLayer = createAsyncThunk(
    'images/updateMaskLayer',
    async ({ public_id, maskData, strokes }: UpdateMaskParams) => {
        const response = await API.post<Layer>(`/cartario/layer/${public_id}/update-mask/`, {
            mask_data: maskData,
            strokes: strokes
        });
        return response.data;
    }
);

export const downloadImage = createAsyncThunk<string, { public_id: string; category: string | null | undefined }, { rejectValue: string }>(
    'images/downloadImage',
    async ({ public_id, category }, { rejectWithValue }) => {
        try {
            // Use API.get and set the response type to 'blob'
            const response = await API.get(`/cartario/image-download/${public_id}/?category=${category ?? 'undefined'}`, {
                responseType: 'blob',  // Ensure the response is a Blob for file download
            });
            // Get extension from response.data
            const contentType = response.headers['content-type'];
            const extension = contentType.split('/')[1];

            // Convert the response data to a URL and create a link for download
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${public_id}.${extension}`; // Set the file name to public_id.extension
            document.body.appendChild(link);
            link.click();

            // Clean up the link and URL
            link.remove();
            window.URL.revokeObjectURL(url);

            return 'Download succeeded';
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('Download failed');
        }
    }
);

export const downloadCollection = createAsyncThunk<string, string, { rejectValue: string }>(
    'images/downloadCollection',
    async (public_id, { rejectWithValue }) => {
        try {
            // Use API.get and set the response type to 'blob'
            const response = await API.get(`/cartario/collection-queue-download/${public_id}`, {
                responseType: 'blob',  // Ensure the response is a Blob for file download
            });

            // Convert the response data to a URL and create a link for download
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${public_id}.zip`; // Set the file name to public_id.zip
            document.body.appendChild(link);
            link.click();

            // Clean up the link and URL
            link.remove();
            window.URL.revokeObjectURL(url);

            return 'Download succeeded';
        } catch (error) {
            if (error instanceof Error) {
                return rejectWithValue(error.message);
            }
            return rejectWithValue('Download failed');
        }
    }
);

export const removeBackgroundFromLayer = createAsyncThunk(
    'images/removeBackgroundFromLayer',
    async ({ public_id, type }: { public_id: string, type: string }) => {
        const response = await API.post(`/cartario/layer/${public_id}/remove-background/`, {
            session_type: type,
        });
        return response.data;
    }
);

export const mergeLayers = createAsyncThunk(
    'images/mergeLayers',
    async ({ public_id, layers }: { public_id: string, layers: Layer[] }) => {
        const response = await API.post(`/cartario/image/${public_id}/merge/`, layers);
        return response.data;
    }
);

export const removeLayer = createAsyncThunk(
    'images/deleteLayer',
    async ({ public_id }: { public_id: string }) => {
        await API.delete(`/cartario/layer/${public_id}/delete-layer/`);
        return { public_id };
    }
);

export const addImageEffect = createAsyncThunk(
    'images/addImageEffect',
    async ({ public_id, effect }: { public_id: string, effect: string }) => {
        const response = await API.post(`/cartario/layer/${public_id}/enhance/`, {enhance_type: effect});
        return response.data;
    }
);

export const saveImage = createAsyncThunk(
    'images/saveImage',
    async ({image_public_id, gallery_public_id}: {image_public_id: string, gallery_public_id: string}) => {
        const response = await API.post(`/cartario/image/${image_public_id}/`, {
            gallery_public_id: gallery_public_id
        });
        return response.data;
    }
);

// Create a slice for images
const imageSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        modifyLayer: (state, action) => {
            const { selectedImage } = state;
            if (!selectedImage) return;

            const layerIndex = selectedImage.layers.findIndex(
                layer => layer.public_id === action.payload.layer.public_id
            );
            if (layerIndex !== -1) {
                selectedImage.layers[layerIndex] = {
                    ...selectedImage.layers[layerIndex],
                    ...action.payload.params
                };
            }
        },
        setLayers: (state, action) => {
            const { selectedImage } = state;
            if (!selectedImage) return;

            selectedImage.layers = action.payload;
        },
        resetLayerPositions: (state) => {
            const { selectedImage } = state;
            if (!selectedImage) return;

            selectedImage.layers = selectedImage.layers.map((layer) => ({
                ...layer,
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            }));
        },
        setMaskPrompt: (state, action) => {
            state.modifyImageSettings.maskPrompt = action.payload;
        },
        setActiveLayer: (state, action) => {
            state.modifyImageSettings.activeLayer = action.payload;
        },
        setHasMask: (state, action) => {
            state.modifyImageSettings.hasMask = action.payload
        },
        openModal: (state, action: PayloadAction<keyof ImageState['activeModals']>) => {
            state.activeModals[action.payload] = true;
        },
        closeModal: (state, action: PayloadAction<keyof ImageState['activeModals']>) => {
            state.activeModals[action.payload] = false;
        },
        enableTool: (state, action) => {
            const toolName = action.payload;
            const activeLayer = state.modifyImageSettings.activeLayer;
            console.log(activeLayer);
            if (!activeLayer || !state.selectedImage) {
                return;
            }

            state.selectedImage.layers.forEach((layer: Layer) => {
                layer.tools?.forEach((tool) => {
                    if(layer.public_id === activeLayer.public_id) {
                        tool.isActive = tool.name === toolName;
                    } else {
                        tool.isActive = false
                    }
                });
            });

            // Set all tools to enabled false first as only one tool can be active
            activeLayer.tools?.forEach(tool => tool.isActive = false);
            const tool = activeLayer.tools?.find(tool => tool.name === toolName);
            if (tool) {
                tool.isActive = true;
            }
        },
        disableTool: (state, action) => {
            const toolName = action.payload;
            const activeLayer = state.modifyImageSettings.activeLayer;

            if (!activeLayer || !state.selectedImage) {
                return;
            }

            state.selectedImage.layers.forEach((layer: Layer) => {
                if(layer.tools?.length) {
                    layer.tools.forEach((tool) => {
                        if(layer.public_id === activeLayer.public_id) {
                            tool.isActive = false;
                        }
                    });
                }
            });

            // Set all tools to enabled false first as only one tool can be active
            activeLayer.tools?.forEach(tool => tool.isActive = false);
            const tool = activeLayer.tools?.find(tool => tool.name === toolName);
            if (tool) {
                tool.isActive = false;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchImages.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchImages.fulfilled, (state, action) => {
                state.loading = false;
                state.collection = action.payload.collection;
                state.collection.trigger_word = action.payload.trigger_word || 'N/A';
                state.images = action.payload.images;
                state.status = action.payload.status;
                state.error_message = action.payload.error_message;
            })
            .addCase(fetchImages.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to fetch images';
            })
            .addCase(sendPrompt.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendPrompt.fulfilled, (state, action) => {
                state.loading = false;
                if(action.payload.length) {
                    state.images = action.payload;
                }
            })
            .addCase(sendPrompt.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to send prompt';
            })
            .addCase(downloadCollection.pending, (state) => {
                state.loading = true;
                state.error_message = null;
            })
            .addCase(downloadCollection.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(downloadCollection.rejected, (state, action) => {
                // Since action.payload can be string or undefined, we default to a fallback message
                state.loading = false;
                state.error_message = action.payload || 'Failed to download collection';
            })
            .addCase(downloadImage.pending, (state) => {
                state.loading = true;
                state.error_message = null;
            })
            .addCase(downloadImage.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(downloadImage.rejected, (state, action) => {
                // Since action.payload can be string or undefined, we default to a fallback message
                state.loading = false;
                state.error_message = action.payload || 'Failed to download collection';
            })
            .addCase(getImage.pending, (state) => {
                state.loading = true;
            })
            .addCase(getImage.fulfilled, (state, action) => {
                state.loading = false;
                const checkLayerProperty = (property: string) => {
                    return action.payload?.layers?.length === 1 && action.payload.layers[0].other_values?.[property] === true;
                };

                const isMerged = checkLayerProperty('merged');
                const enhancedShadows = checkLayerProperty('enhanced_shadows');
                const enhancedLighting = checkLayerProperty('enhanced_lighting');

                state.selectedImage = action.payload;
                // state.modifyImageSettings.activeLayer = action.payload.layers[0] || null;
                state.modifyImageSettings.isMerged = isMerged;
                state.modifyImageSettings.enhancedShadows = enhancedShadows;
                state.modifyImageSettings.enhancedLighting = enhancedLighting;

                const checkHasMask = action.payload.layers.filter((layer: Layer) => layer.type === 'mask').length > 0
                state.modifyImageSettings.hasMask = checkHasMask;
                if(checkHasMask) {
                    const maskLayer = action.payload.layers.find((layer: Layer) => layer.type === 'mask');
                    if(!maskLayer) {
                        return;
                    }
                    state.modifyImageSettings.activeLayer = maskLayer
                }
            })
            .addCase(getImage.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to fetch image';
            })
            .addCase(addLayer.pending, (state) => {
                state.loading = true;
            })
            .addCase(addLayer.fulfilled, (state, action) => {
                state.loading = false;
                if(state.selectedImage) {
                    const existingPID =  state.selectedImage.layers.find(
                        layer => layer.public_id === action.payload.public_id
                    )
                    if(!existingPID) {
                        state.selectedImage.layers.push(
                            action.payload
                        );
                    }
                }

                const checkHasMask = action.payload.type === 'mask';
                state.modifyImageSettings.hasMask = checkHasMask;
                if(checkHasMask) {
                    state.modifyImageSettings.activeLayer = action.payload;
                }
            })
            .addCase(addLayer.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to add layer';
            })
            .addCase(removeBackgroundFromLayer.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeBackgroundFromLayer.fulfilled, (state, action) => {
                state.loading = false;
                if(state.selectedImage) {
                    const layerIndex = state.selectedImage.layers.findIndex(
                        layer => layer.public_id === action.payload.public_id
                    );
                    if (layerIndex !== -1) {
                        state.selectedImage.layers[layerIndex] = action.payload;
                    }
                }
            })
            .addCase(removeBackgroundFromLayer.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to remove background';
            })
            .addCase(mergeLayers.pending, (state) => {
                state.loading = true;
            })
            .addCase(mergeLayers.fulfilled, (state, action) => {
                state.loading = false;
                const isProduct = action.payload.is_product ?? false;
                if(state.selectedImage && !isProduct) {
                    state.selectedImage.layers = action.payload;
                }
            })
            .addCase(mergeLayers.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to merge layers';
            })
            .addCase(removeLayer.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeLayer.fulfilled, (state, action) => {
                state.loading = false;
                if(state.selectedImage) {
                    state.selectedImage.layers = state.selectedImage.layers.filter(
                        layer => layer.public_id !== action.payload.public_id
                    );
                }
            })
            .addCase(removeLayer.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to delete layer';
            })
            .addCase(addImageEffect.pending, (state) => {
                state.loading = true;
            })
            .addCase(addImageEffect.fulfilled, (state, action) => {
                state.loading = false;
                if(state.selectedImage) {
                    const layerIndex = state.selectedImage.layers.findIndex(
                        layer => layer.public_id === action.payload.public_id
                    );
                    if (layerIndex !== -1) {
                        state.selectedImage.layers[layerIndex] = action.payload;
                    }
                }
            })
            .addCase(addImageEffect.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to add effect';
            })
            .addCase(saveImage.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveImage.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(saveImage.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to save image';
            })
            .addCase(activateMaskLayer.pending, (state) => {
                state.loading = true;
            })
            .addCase(activateMaskLayer.fulfilled, (state, action) => {
                state.loading = false;
                if(state.selectedImage) {
                    state.selectedImage.layers = action.payload;
                }
            })
            .addCase(activateMaskLayer.rejected, (state, action) => {
                state.loading = false;
                state.error_message = action.error.message || 'Failed to activate mask';
            });
    },
});

export const {
    modifyLayer,
    resetLayerPositions,
    setMaskPrompt,
    setActiveLayer,
    setHasMask,
    setLayers,
    openModal,
    closeModal,
    enableTool,
    disableTool,
} = imageSlice.actions;
export default imageSlice.reducer;
