import {Box} from "@chakra-ui/react";
import {Layer} from "~/utils/types.ts";
import ModifyLayer from "~/apps/backend/components/tools/ModifyLayer.tsx";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "~/store";
import {modifyLayer, setActiveLayer} from "~/store/imageSlice.ts";

const ModifyImageLayers = () => {
    const dispatch = useDispatch<AppDispatch>();

    const {
        activeLayer,
        defaultCanvasSize,
        defaultCanvasHeight
    } = useSelector((state: RootState) => state.images.modifyImageSettings);
    const image = useSelector((state: RootState) => state.images.selectedImage);

    const updateLayer = (params: any) => {
        console.log('Init updateLayer');

        if (!activeLayer) return;

        console.log('updating params!');
        console.log(params);
        const updatedLayer = {
            ...activeLayer,
            ...params
        };

        dispatch(modifyLayer({
            params: params,
            layer: activeLayer,
        }));
        dispatch(setActiveLayer(updatedLayer));
    };
    return (
            <Box
                flex="1"
                position="relative"
                width={`${defaultCanvasSize}px`}
                height={`${defaultCanvasHeight}px`}
                mx="auto"
                bg="gray.900"
                overflow="hidden"
            >
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                >
                    {image && image.layers && image.layers.map((layer: Layer, index: number) => (
                        <ModifyLayer
                            key={`${layer.public_id}-${index}`}
                            layer={layer}
                            index={index}
                            isActive={activeLayer?.public_id === layer.public_id}
                            onUpdate={updateLayer}
                            maxWidth={defaultCanvasSize}
                            maxHeight={defaultCanvasHeight}
                        />
                    ))}
                </Box>
            </Box>
        )

}

export default ModifyImageLayers;