import React from 'react';
import { Box, Button, Tooltip, HStack, Icon } from "@chakra-ui/react";
import { FaImage, FaTextHeight, FaMask } from 'react-icons/fa';
import { MdDragIndicator } from 'react-icons/md';
import {Layer} from "~/utils/types.ts";
import LayerToolbar from "~/apps/backend/components/tools/LayerToolbar.tsx";
import {useLayerManagement} from "~/hooks/useLayerManagement.ts";

interface DraggableLayerProps {
    layer: Layer;
    index: number;
    layers: Layer[];
}

const DraggableLayer: React.FC<DraggableLayerProps> = ({
                                                           layer,
                                                           index,
                                                           layers
                                                       }) => {
    const {
        activeLayer,
        dispatchActiveLayer,
        updateLayer,
        cloneLayer,
        deleteLayer,
        removeBackground,
        setLayerIndexes
    } = useLayerManagement();

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        dispatchActiveLayer(null);
        const draggedIdx = parseInt(e.dataTransfer.getData('text/plain'));
        const dropIdx = index;

        if (draggedIdx === dropIdx) return;
        // Create a copy of the layers array
        const updatedLayers = [...layers];
        console.log('Before update:', updatedLayers.map(l => ({ id: l.public_id, z: l.z_index })));

        const draggedLayer = updatedLayers[draggedIdx];
        console.log('Dragged layer:', draggedLayer.public_id, 'from index:', draggedIdx, 'to index:', dropIdx);

        // Remove the dragged layer from its original position
        updatedLayers.splice(draggedIdx, 1);
        console.log('After remove:', updatedLayers.map(l => ({ id: l.public_id, z: l.z_index })));

        // Insert it at the new position
        updatedLayers.splice(dropIdx, 0, draggedLayer);
        console.log('After insert:', updatedLayers.map(l => ({ id: l.public_id, z: l.z_index })));
        console.log('updated layers:', updatedLayers);
        // Use the total number of layers to calculate z-index
        const totalLayers = updatedLayers.length;
        // Update z-indexes based on the total number of layers
        const newLayers = updatedLayers.map((layer, idx) => ({
            ...layer,
            z_index: totalLayers - idx
        }));

        // Update the state with the new layers
        setLayerIndexes(newLayers);

    };

    const getLayerIcon = (type: string) => {
        switch (type) {
            case 'image':
                return FaImage;
            case 'text':
                return FaTextHeight;
            default:
                return FaMask;
        }
    };

    return (
        <Box
            width="100%"
            draggable
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{ touchAction: 'none' }}
        >
            <Tooltip label={`${layer.type.charAt(0).toUpperCase() + layer.type.slice(1)} Layer ${index}`}>
                <Button
                    aria-label={`${layer.type} Layer ${index}`}
                    bg={activeLayer?.public_id === layer.public_id ? 'gray.500' : 'gray.600'}
                    onClick={() => dispatchActiveLayer(layer)}
                    cursor="pointer"
                    width="100%"
                    textAlign="center"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    _hover={{ bg: activeLayer?.public_id === layer.public_id ? 'gray.400' : 'gray.500' }}
                >
                    <HStack spacing={2}>
                        <Icon
                            as={MdDragIndicator}
                            cursor="grab"
                            _active={{ cursor: "grabbing" }}
                            color="gray.300"
                        />
                        <Icon as={getLayerIcon(layer.type)} />
                    </HStack>
                    <Box>{layer?.public_id?.substring(0,4)}</Box>
                </Button>
            </Tooltip>
            {activeLayer?.public_id === layer.public_id && (
                <LayerToolbar
                    layer={layer}
                    onClone={() => cloneLayer(layer.public_id!)}
                    onDelete={() => deleteLayer(layer.public_id!)}
                    onRmBg={removeBackground}
                    onUpdate={(params: Partial<Layer>) => updateLayer({
                        params: params,
                        layer: layer
                    })}
                />
            )}
        </Box>
    );
};

export default DraggableLayer;