import React, { useState } from 'react';
import {
    Box, Flex, IconButton, useDisclosure, Drawer, DrawerOverlay,
    DrawerContent, DrawerCloseButton, useBreakpointValue,
    Alert, AlertIcon, CloseButton
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Sidebar from "../components/Sidebar.tsx";
import { useLocation } from "react-router-dom";

interface MainLayoutProps {
    children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const location = useLocation();
    const [showBanner, setShowBanner] = useState(true);
    const isEditMediaPage = location.pathname.includes('/c-panel/modify-image/');
    const isProductEditPage = location.pathname.includes('/c-panel/product/');

    const shouldHideSidebar = isMobile || isEditMediaPage || isProductEditPage;
    return (
        <Flex direction="column" h="100vh" color="primary.0">
            {/* Banner */}
            {showBanner && (
                <Alert
                    status="info"
                    bg="blue.500"
                    color="white"
                    p={5}
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    zIndex="1001"
                >
                    <AlertIcon color="white" />
                    We're in the process of upgrading Cartario. You may experience some unusual activity while this takes place.
                    <CloseButton
                        position="absolute"
                        right="8px"
                        top="5px"
                        onClick={() => setShowBanner(false)}
                    />
                </Alert>
            )}

            {/* Main content area with flex */}
            <Flex flex="1" overflow="hidden">
                {/* Sidebar shown only on larger screens */}
                {!shouldHideSidebar && (
                    <Box flexShrink={0} bg="brand.800">
                        <Sidebar />
                    </Box>
                )}

                {/* Hamburger icon for mobile */}
                {shouldHideSidebar && (
                    <IconButton
                        aria-label="Open menu"
                        icon={<HamburgerIcon />}
                        onClick={onOpen}
                        position="fixed"
                        top={showBanner ? "14" : "2"}
                        left="6"
                        bg="gray.500"
                        zIndex="1000"
                    />
                )}

                {/* Mobile drawer */}
                <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                    <DrawerOverlay>
                        <DrawerContent maxW="250px" p="0">
                            <DrawerCloseButton top="4" right="4" color="white" zIndex="1" />
                            <Sidebar />
                        </DrawerContent>
                    </DrawerOverlay>
                </Drawer>

                {/* Main content */}
                <Box
                    flex="1"
                    overflowY="auto"
                    pt={showBanner ? "16" : "0"} // Add padding when banner is shown
                >
                    {children}
                </Box>
            </Flex>
        </Flex>
    );
}

export default MainLayout;