// billingSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '~/store';
import API from "~/utils/api.ts";
import { loadStripe } from '@stripe/stripe-js';
import {BillingStatus} from "~/utils/types.ts";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

// Define the shape of the state
export interface PricingPlan {
    title: string;
    price: string;
    description: string;
    features: string[];
    stripe_product_id: string;
    stripe_price_id: string;
    is_popular: boolean;
    order: number;
    credits: number;
    is_recurrent: boolean;
}

interface BillingState {
    plans: PricingPlan[];
    loading: boolean;
    error: string | null;
    billingStatus: BillingStatus;
}

// Initial state
const initialState: BillingState = {
    plans: [],
    loading: false,
    error: null,
    billingStatus: {
        remaining_images: 0,
        remaining_models: 0,
        user: null,
        billing_plan_id: null,
        billing_price_id: null,
        order: 0,
        billing_valid: false,
    },
};

// Async thunk to fetch pricing plans
export const fetchPricingPlans = createAsyncThunk<
    PricingPlan[],
    void,
    { rejectValue: string }
>(
    'billing/fetchPricingPlans',
    async (_, { rejectWithValue }) => {
        try {
            const response = await API.get(`/billing/pricing/`);
            return response.data;
        } catch (error: any) {
            console.log("ERROR??");
            console.log(error);
            return rejectWithValue(error.message || 'Failed to fetch pricing plans');
        }
    }
);

export const createCheckoutSession = createAsyncThunk<
    string,
    { price_id: string; recurringBilling: boolean },
    { rejectValue: string }
>(
    'billing/createCheckoutSession',
    async ({ price_id, recurringBilling }, { rejectWithValue }) => {
        try {
            const { data } = await API.post('/billing/create-checkout-session/', { price_id, recurringBilling });
            return data.sessionId;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to create checkout session');
        }
    }
);

export const confirmPayment = createAsyncThunk<
    void,
    { public_id: string, is_recurring: string | boolean },
    { rejectValue: string }
>(
    'billing/confirmPayment',
    async ({ public_id, is_recurring }, { rejectWithValue }) => {
        try {
            const response = await API.get(`/billing/success/${public_id}/?recurring=${is_recurring}`);
            if (response.status === 200 || response.status === 201) {
                return;
            } else {
                return rejectWithValue(response.data.error || 'Failed to confirm payment');
            }
        } catch (error: any) {
            // Check if the error contains a response object
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data.error || 'An unexpected error occurred');
            }
            return rejectWithValue(error.message || 'Failed to confirm payment');
        }
    }
);

export const fetchBillingStatus = createAsyncThunk<
    BillingStatus,
    void,
    { rejectValue: string }
>(
    'billing/fetchBillingStatus',
    async (_, { rejectWithValue }) => {
        try {
            const response = await API.get('/billing/check/');
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to check billing status');
        }
    }
);

// Create the slice
const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPricingPlans.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPricingPlans.fulfilled, (state, action) => {
                state.loading = false;
                state.plans = action.payload;
            })
            .addCase(fetchPricingPlans.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to load plans';
            })
            .addCase(createCheckoutSession.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createCheckoutSession.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(createCheckoutSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to initiate checkout';
            })
            .addCase(confirmPayment.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(confirmPayment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(confirmPayment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Failed to confirm payment';
            })
            .addCase(fetchBillingStatus.fulfilled, (state, action) => {
                state.billingStatus = action.payload;
            })
            .addCase(fetchBillingStatus.rejected, (state, action) => {
                state.error = action.payload || 'Failed to check billing status';
                state.billingStatus = {
                    user: null,
                    billing_plan_id: null,
                    billing_price_id: null,
                    order: 0,
                    remaining_images: 0,
                    remaining_models: 0,
                    billing_valid: false,
                }
            });
    },
});

// Export actions, selectors, and reducer
export const selectPlans = (state: RootState) => state.billing.plans;
export const selectLoading = (state: RootState) => state.billing.loading;
export const selectError = (state: RootState) => state.billing.error;
export const selectBillingStatus = (state: RootState) => state.billing.billingStatus;

export default billingSlice.reducer;
export { stripePromise };
