import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import API from "~/utils/api.ts";
import {DefaultModelSetting} from "~/utils/types.ts";



interface DefaultModelResult {
    public_id: string;
    image_full: string;
    prompt: string;
}

interface DefaultModel {
    settings: DefaultModelSetting[];
    results?: DefaultModelResult[];
    category: string;
    public_id: string;
    name: string;
    description: string;
    full_image: string;
    trigger_word: string;
    credit_cost: number;
    remaining_credits: number;
    link: string | null;
    link_text: string | null;
}

export interface GenerationResponse {
    image_url: string;
    image_id: string;
}

interface ModelState {
    models: DefaultModel[];
    loading: boolean;
    loadedModels: boolean;
    model: DefaultModel;
    latestResults: GenerationResponse[];
}

const initialState: ModelState = {
    models: [],
    loading: false,
    loadedModels: false,
    model: {
        settings: [],
        category: '',
        public_id: '',
        name: '',
        description: '',
        full_image: '',
        trigger_word: '',
        credit_cost: 0,
        remaining_credits: 0,
        link: null,
        link_text: null,
    },
    latestResults: [],
};

export const fetchDefaultModels = createAsyncThunk(
    'images/fetchDefaultModels',
    async () => {
        const response = await API.get('/cartario/models/', {});
        return response.data as DefaultModel[];
    }
)

export const fetchModel = createAsyncThunk(
    'images/fetchDefaultModel',
    async (payload: {publicId: string; category:string}) => {
        const response = await API.get(`/cartario/model/${payload.publicId}/?category=${payload.category}`, {});
        return response.data as DefaultModel;
    }
)

export const generateImage = createAsyncThunk(
    'images/generateImageFromDefault',
    async (payload: any) => {
        const response = await API.post(`/cartario/model/${payload.public_id}/`, payload);
        return response.data as GenerationResponse[];
    }
)

const modelSlice = createSlice({
    name: 'models',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDefaultModels.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchDefaultModels.fulfilled, (state, action) => {
                state.models = action.payload;
                state.loading = false;
                state.loadedModels = true;
            })
            .addCase(fetchDefaultModels.rejected, (state) => {
                state.loading = false;
            })
            .addCase(generateImage.pending, (state) => {
                state.loading = true;
            })
            .addCase(generateImage.fulfilled, (state, action) => {
                state.loading = false;
                state.latestResults = [...action.payload, ...state.latestResults || []];
            })
            .addCase(generateImage.rejected, (state) => {
                state.loading = false;
            })
            .addCase(fetchModel.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchModel.fulfilled, (state, action) => {
                state.model = action.payload;
                state.latestResults = [];
                state.loading = false;
            })
            .addCase(fetchModel.rejected, (state) => {
                state.loading = false;
            });
    }
});

export default modelSlice.reducer;

