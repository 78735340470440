import React, { useRef } from 'react';
import { Box, Heading, Text, Button, VStack, Badge, List, ListItem, useDisclosure, useToast, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~/store';
import { createCheckoutSession, fetchBillingStatus } from '~/store/billingSlice';
import { stripePromise } from '~/store/billingSlice';
import API from '~/utils/api';

interface PricingCardProps {
    title: string;
    price: string;
    features: string[];
    popular?: boolean;
    price_id: string;
    order: number;
    recurringBilling: boolean;
    billingStatus: {
        user: string | null;
        billing_plan_id: string | null;
        billing_price_id: string | null;
        order: number | null;
    } | null;
}

const PricingCard: React.FC<PricingCardProps> = ({ title, price, features, popular, price_id, billingStatus, order, recurringBilling }) => {
    const dispatch = useDispatch<AppDispatch>();
    const toast = useToast();
    const { isOpen: isUnsubscribeOpen, onOpen: onUnsubscribeOpen, onClose: onUnsubscribeClose } = useDisclosure();
    const { isOpen: isUpgradeOpen, onOpen: onUpgradeOpen, onClose: onUpgradeClose } = useDisclosure();
    const { isOpen: isDowngradeOpen, onOpen: onDowngradeOpen, onClose: onDowngradeClose } = useDisclosure();
    const cancelRef = useRef(null);

    const handleCheckout = async () => {
        const stripe = await stripePromise;
        const { payload } = await dispatch(createCheckoutSession({ price_id, recurringBilling }));
        if (stripe && typeof payload === 'string') {
            stripe.redirectToCheckout({ sessionId: payload });
        }
    };

    const handlePlanChange = async () => {
        try {
            await API.post('/billing/change-plan/', { new_price_id: price_id });
            toast({
                title: "Plan changed successfully",
                description: "Your subscription plan has been updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            dispatch(fetchBillingStatus()); // Refresh billing status in the main section

            // Close upgrade or downgrade modal if successful
            onUpgradeClose();
            onDowngradeClose();
        } catch (error) {
            toast({
                title: "Failed to change plan",
                description: "There was an error updating your subscription. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleCancelSubscription = async () => {
        try {
            await API.post('/billing/cancel/');
            toast({
                title: "Subscription canceled",
                description: "Your subscription has been successfully canceled.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            dispatch(fetchBillingStatus()); // Refresh billing status in the main section
            onUnsubscribeClose(); // Close unsubscribe modal if successful
        } catch (error) {
            toast({
                title: "Failed to cancel subscription",
                description: "There was an error canceling your subscription. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const isCurrentPlan = billingStatus?.billing_price_id === price_id;

    const buttonText = !billingStatus?.user
        ? "Free Trial"
        : isCurrentPlan
            ? "Unsubscribe"
            : billingStatus.billing_price_id
                ? billingStatus.order !== null && billingStatus.order < order
                    ? "Upgrade"
                    : "Downgrade"
                : "Subscribe";

    const buttonAction = () => {
        if (!recurringBilling) {
            handleCheckout();
            return;
        }

        if (buttonText === "Free Trial") {
            window.location.href = "/register";
        } else if (buttonText === "Unsubscribe") {
            onUnsubscribeOpen();
        } else if (buttonText === "Subscribe") {
            handleCheckout();
        } else if (buttonText === "Upgrade") {
            onUpgradeOpen();
        } else if (buttonText === "Downgrade") {
            onDowngradeOpen();
        }
    };

    return (
        <Box
            border="1px solid"
            borderColor={isCurrentPlan ? "green.500" : "black"}
            bg={popular ? "black" : "white"}
            color={popular ? "white" : "black"}
            borderRadius="md"
            p={6}
            py={popular ? 10 : 6}
            textAlign="center"
            position="relative"
            mb={4}
        >
            {popular && (
                <Badge
                    bg="white"
                    color="black"
                    fontSize="0.9rem"
                    px={4}
                    py={1}
                    mb={4}
                    borderRadius="lg"
                >
                    POPULAR
                </Badge>
            )}
            <Heading fontSize="md" mb={1}>
                {title}
            </Heading>
            <Text fontSize="3xl" color="body.2" fontWeight="600" mb={6}>
                {price.replace('/mo', '')}
            </Text>
            <VStack spacing={3} mb={6}>
                <List spacing={3}>
                    {features.map((feature, index) => (
                        <ListItem key={index}>
                            <Text color={popular ? 'white' : 'body.0'}>
                                {feature}
                            </Text>
                        </ListItem>
                    ))}
                </List>
            </VStack>
            <Button
                bg={isCurrentPlan ? "red.500" : popular ? "#1e90ff" : "transparent"}
                color={popular ? "white" : "gray.800"}
                size="large"
                border="1px solid black"
                borderRadius="full"
                _hover={{ bg: isCurrentPlan ? "red.600" : popular ? "#187bcd" : "gray.200" }}
                px={8}
                py={4}
                mt={4}
                mb={4}
                onClick={buttonAction}
            >
                {recurringBilling === true ? buttonText : "Buy" }
            </Button>

            {/* Unsubscribe Confirmation Modal */}
            <AlertDialog
                isOpen={isUnsubscribeOpen}
                leastDestructiveRef={cancelRef}
                onClose={onUnsubscribeClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Unsubscribe
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to unsubscribe? This action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onUnsubscribeClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleCancelSubscription} ml={3}>
                                Unsubscribe
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* Upgrade Confirmation Modal */}
            <AlertDialog
                isOpen={isUpgradeOpen}
                leastDestructiveRef={cancelRef}
                onClose={onUpgradeClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Upgrade
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Upgrading your plan will add an additional prorated charge for this cycle. Do you want to proceed?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onUpgradeClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="blue" onClick={handlePlanChange} ml={3}>
                                Upgrade
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* Downgrade Confirmation Modal */}
            <AlertDialog
                isOpen={isDowngradeOpen}
                leastDestructiveRef={cancelRef}
                onClose={onDowngradeClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Downgrade
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Downgrading may result in loss of access to certain features. Are you sure you want to proceed?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDowngradeClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="orange" onClick={handlePlanChange} ml={3}>
                                Downgrade
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default PricingCard;
