import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Draggable from 'react-draggable';
import { Box } from '@chakra-ui/react';
import { AppDispatch } from '~/store';
import { updateMaskLayer } from '~/store/imageSlice';
import MaskCanvas from './MaskCanvas';
import { Layer, Stroke } from "~/utils/types";

interface ResizableLayerProps {
    layer: Layer;
    index: number;
    isActive: boolean;
    onUpdate: (updates: {
        x?: number;
        y?: number;
        width?: number;
        height?: number;
        public_id?: string;
        value?: string;
        other_values?: {
            backgroundRemoved?: string;
            hasEraserChanges?: boolean;
            [key: string]: any;
        };
    }) => void;
    maxWidth?: number;
    maxHeight?: number;
}

const ModifyLayer: React.FC<ResizableLayerProps> = ({
                                                           layer,
                                                           index,
                                                           isActive,
                                                           onUpdate,
                                                           maxWidth = 600,
                                                           maxHeight = 800
                                                       }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [dimensions, setDimensions] = useState({ width: layer.width || maxWidth, height: layer.height || maxHeight });
    const [isResizing, setIsResizing] = useState(false);
    // const [isDrawing, setIsDrawing] = useState(false);
    const dragRef = useRef(null);
    const startResizeRef = useRef({ x: 0, width: 0 });
    const aspectRatioRef = useRef(dimensions.width / dimensions.height);
    const [isResizeToolActive, setIsResizeToolActive] = useState(false);
    const [isEraseToolActive, setIsEraseToolActive] = useState(false);
    const eraserCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const eraserContextRef = useRef<CanvasRenderingContext2D | null>(null);

    useEffect(() => {
        if (!layer.width || !layer.height) {
            const initialSize = Math.min(maxWidth, maxHeight);
            setDimensions({ width: initialSize, height: initialSize });
            onUpdate({ width: initialSize, height: initialSize, public_id: layer.public_id });
        } else {
            aspectRatioRef.current = layer.width / layer.height;
        }
    }, [layer.public_id]);
    const createRedCircleCursor = (size: number): string => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return 'default'; // Fallback if no context is available

        // Set canvas dimensions to fit the circle
        canvas.width = size;
        canvas.height = size;

        // Draw the red circle
        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
        ctx.fillStyle = 'red';
        ctx.fill();

        // Return as a data URL for cursor
        return canvas.toDataURL();
    };
    // Handle tool state changes
    useEffect(() => {
        const newEraseToolActive = layer.tools?.find(tool => tool.name === 'eraser')?.isActive || false;
        const newResizeToolActive = layer.tools?.find(tool => tool.name === 'resize')?.isActive || false;

        setIsEraseToolActive(newEraseToolActive);
        setIsResizeToolActive(newResizeToolActive);
    }, [layer.tools]);

    const preprocessEraserMask = (
        ctx: CanvasRenderingContext2D,
        width: number,
        height: number
    ): void => {
        const imageData = ctx.getImageData(0, 0, width, height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
            // If alpha is below a threshold, make it fully transparent
            data[i + 3] = data[i + 3] > 128 ? 255 : 0; // Threshold at 128
        }

        ctx.putImageData(imageData, 0, 0);
    };

    const handleEraserMouseUp = () => {
        if (!isEraseToolActive || !eraserCanvasRef.current || !isActive) return;

        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = dimensions.width;
        tempCanvas.height = dimensions.height;
        const tempCtx = tempCanvas.getContext('2d');

        if (tempCtx && eraserCanvasRef.current) {
            // First draw the eraser canvas (the mask)
            tempCtx.drawImage(eraserCanvasRef.current, 0, 0);
            preprocessEraserMask(tempCtx, dimensions.width, dimensions.height);

            // Set composite operation to source-in - this will only keep the parts of the
            // new image where the mask was drawn
            tempCtx.globalCompositeOperation = 'source-in';

            const img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => {
                // Draw the original image - it will only show through where the mask is transparent
                tempCtx.drawImage(img, 0, 0, dimensions.width, dimensions.height);

                // Update the layer with new image data
                onUpdate({
                    public_id: layer.public_id,
                    other_values: {
                        ...layer.other_values,
                        backgroundRemoved: tempCanvas.toDataURL('image/png', 1.0)
                    }
                });
            };
            img.src = layer.other_values?.backgroundRemoved || layer.value;
        }
    };

    const handleResize = (e: React.MouseEvent) => {
        if (!isResizeToolActive) return;

        e.stopPropagation();
        e.preventDefault();

        startResizeRef.current = {
            x: e.clientX,
            width: dimensions.width
        };

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const deltaX = moveEvent.clientX - startResizeRef.current.x;
            const newWidth = Math.max(50, Math.min(startResizeRef.current.width + deltaX, maxWidth - layer.x));
            const newHeight = newWidth / aspectRatioRef.current;

            if (newHeight <= maxHeight - layer.y) {
                setDimensions({ width: newWidth, height: newHeight });
                onUpdate({
                    width: newWidth,
                    height: newHeight,
                    public_id: layer.public_id
                });
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            setIsResizing(false);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        setIsResizing(true);
    };

    const handleDrag = (_e: any, data: { x: number; y: number }) => {
        if (isResizing || !isResizeToolActive) return;

        const newX = Math.max(0, Math.min(data.x, maxWidth - dimensions.width));
        const newY = Math.max(0, Math.min(data.y, maxHeight - dimensions.height));

        onUpdate({
            x: newX,
            y: newY,
            public_id: layer.public_id
        });
    };

    const preventDragHandler = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const initializeEraserCanvas = () => {
        if (!eraserCanvasRef.current) return;

        eraserCanvasRef.current.width = dimensions.width;
        eraserCanvasRef.current.height = dimensions.height;
        eraserContextRef.current = eraserCanvasRef.current.getContext('2d');

        const img = new Image();
        const eraserSize = layer.tools?.find(tool => tool.name === 'eraser')?.size || 30;
        // Generate a red circle of 30px size
        const customCursor = createRedCircleCursor(eraserSize);

        // Set the cursor style on the canvas
        if (eraserCanvasRef.current) {
            eraserCanvasRef.current.style.cursor = `url(${customCursor}) 15 15, crosshair`;
        }

        img.crossOrigin = "anonymous";
        img.src = layer.other_values?.backgroundRemoved || layer.value;

        img.onload = () => {
            if (eraserContextRef.current) {
                eraserContextRef.current.clearRect(0, 0, dimensions.width, dimensions.height);
                eraserContextRef.current.drawImage(img, 0, 0, dimensions.width, dimensions.height);
            }
        };
    };

    useEffect(() => {
        if (isEraseToolActive) {
            initializeEraserCanvas();
        }
    }, [isEraseToolActive]);

    const handleEraser = (e: React.MouseEvent) => {
        if (!isEraseToolActive || !eraserContextRef.current || !isActive) return;

        const rect = eraserCanvasRef.current?.getBoundingClientRect();
        if (!rect) return;

        const eraserSize = layer.tools?.find(tool => tool.name === 'eraser')?.size || 30;
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        eraserContextRef.current.globalCompositeOperation = 'destination-out';
        eraserContextRef.current.beginPath();
        eraserContextRef.current.arc(x, y, eraserSize / 2, 0, Math.PI * 2, false);
        eraserContextRef.current.fill();
    };

    const renderContent = () => {
        if (layer.type === 'mask') {
            return (
                <MaskCanvas
                    isActive={isActive}
                    width={dimensions.width}
                    height={dimensions.height}
                    savedStrokes={layer.strokes || []}
                    onSave={(maskData: string, strokes: Stroke[]) => {
                        layer.public_id && dispatch(updateMaskLayer({
                            public_id: layer.public_id,
                            maskData,
                            strokes
                        }));
                    }}
                />
            );
        }

        if (layer.type === 'image') {
            return (
                <>
                    {isEraseToolActive && isActive && (
                        <canvas
                            ref={eraserCanvasRef}
                            style={{width: '100%', height: '100%', cursor: 'crosshair'}}
                            onMouseDown={handleEraser}
                            onMouseMove={(e) => e.buttons === 1 && handleEraser(e)}
                            onMouseUp={handleEraserMouseUp}
                            // onMouseLeave={handleEraserMouseUp}
                        />
                    )}
                    <img
                        src={layer.other_values?.backgroundRemoved || layer.value}
                        alt={`Layer ${index}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            display: (isEraseToolActive && isActive) ? 'none' : 'block'
                        }}
                        onDragStart={preventDragHandler}
                        crossOrigin="anonymous"
                    />
                </>
            );
        }

        return <Box style={layer.style}>{layer.value}</Box>;
    };

    const resizeHandles = isActive && isResizeToolActive && (
        <>
            <Box
                position="absolute"
                inset={0}
                boxShadow="inset 0 0 0 2px #3182ce"
                pointerEvents="none"
            />
            <Box
                position="absolute"
                bottom={0}
                right={0}
                width="20px"
                height="20px"
                bg="blue.500"
                cursor="se-resize"
                borderRadius="sm"
                opacity={0.8}
                _hover={{ opacity: 1 }}
                onMouseDown={handleResize}
            />
            <Box
                position="absolute"
                top={0}
                left={0}
                bg="blue.500"
                color="white"
                px={2}
                py={1}
                borderRadius="md"
                fontSize="sm"
            >
                {`${layer.type.charAt(0).toUpperCase() + layer.type.slice(1)} Layer ${index + 1}`}
            </Box>
        </>
    );

    const containerProps = isResizeToolActive ? {
        nodeRef: dragRef,
        position: { x: layer.x, y: layer.y },
        onDrag: handleDrag,
        // disabled: isResizing || isDrawing,
        disabled: isResizing,
        bounds: {
            left: 0,
            top: 0,
            right: maxWidth - dimensions.width,
            bottom: maxHeight - dimensions.height
        }
    } : {
        nodeRef: dragRef,
        position: { x: layer.x, y: layer.y },
        disabled: true
    };

    return (
        <Draggable {...containerProps}>
            <Box
                ref={dragRef}
                position="absolute"
                zIndex={layer.z_index}
                style={{
                    opacity: layer.style?.opacity,
                    cursor: isResizeToolActive ? (isResizing ? 'default' : 'move') : 'default',
                    userSelect: 'none'
                }}
            >
                <Box position="relative" width={dimensions.width} height={dimensions.height}>
                    {renderContent()}
                    {resizeHandles}
                </Box>
            </Box>
        </Draggable>
    );
};

export default ModifyLayer;
