import { useParams } from 'react-router-dom';
import ModifyImage from "~/apps/backend/components/ModifyImage.tsx";

const EditImage = () => {
    const { public_id } = useParams<{ public_id: string }>();
    return (
        <>
            <ModifyImage publicId={public_id} editorStyle="advanced" />
        </>
    );
};

export default EditImage;