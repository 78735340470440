import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import API from '../utils/api';
import API_URLS from '../utils/urls';
import {RootState} from "~/store/index.ts";
import {CollectionCategory} from "~/utils/types.ts";

// Define the structure of an individual collection item
interface Collection {
    id: number;
    name: string;
    public_id: string;
    description: string | null;
    created_at: string;
    updated_at: string;
    state: string;
    images: Array<{
        id: number;
        collection: number;
        image_file: string;
        caption: string;
        uploaded_at: string;
    }>;
    training_status: string;
    training_type: string;
    image: string;
    trigger_word: string;
    model_type: string;
    default_id: number;
}

interface TrainingStatus {
    public_id: string;
    status: string;
    error_message?: string;
}


// Define the structure of the collection state
interface CollectionState {
    collections: Collection[];
    count: number;
    next: string | null;
    previous: string | null;
    loading: boolean;
    error: string | null;
    currentCollection: Collection | null; // Added for current collection details
    trainingStatus: TrainingStatus | null; // Added for current training status
    models: CollectionCategory[];
    loadedModels: boolean;
}

// Initial state with pagination fields
const initialState: CollectionState = {
    collections: [],
    count: 0,
    next: null,
    previous: null,
    loading: false,
    error: null,
    currentCollection: null,
    trainingStatus: null,
    models: [
        {
            name: 'FLUX1.1 PRO',
            replace_name: null,
            description: 'FLUX.1 [pro] is the best of FLUX.1, offering state-of-the-art performance image generation with top of the line prompt following, visual quality, image detail and output diversity.',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/0461d05a-757b-4726-82b3-022d244d8dcd/queue/5aa0a37a-f980-4bba-93e2-1ae9192946b8/ebdf6c33-5322-40b7-911a-583fab7fc1da.jpg',
            category: 'flux',
            public_id: '',
            model_type: 'flux',
            defaultId: 3,
        },
        {
            name: 'FLUX1.1 PRO ULTRA',
            replace_name: null,
            description: 'FLUX1.1 [pro] – High-Resolution: Create bigger, better images. BlackForest\'s new high-resolution mode lets you generate images up to 4MP in just 10 seconds.',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/ec8c2b1f-dcdf-4cb3-b8a2-34f7c3dfb6d6/queue/7a565e4d-e06b-43f8-b5dc-d591f8f5148d/d9e75d1e-fb65-42c2-bb6c-ca46051977fd.jpg',
            category: 'flux',
            public_id: '',
            model_type: 'flux',
            defaultId: 6,
        },
        {
            name: 'FLUX1.1 PRO ULTRA RAW',
            replace_name: null,
            description: 'FLUX1.1 [pro] – Raw Mode: Want more natural-looking images? BlackForest\'s new raw mode gives you a less processed, more authentic look. It\'s great for portraits and nature photos.',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/4f35affb-349e-46fb-acf6-0dfaf4e6ed45/queue/f568c639-2157-4880-aa16-600c48c4ac22/d8ed5022-ff1f-476b-9d3a-485dcb6c02d2.jpg',
            category: 'flux',
            public_id: '',
            model_type: 'flux',
            defaultId: 7,
        },
        {
            name: 'Red Dress',
            replace_name: null,
            description: 'A custom model trained on cartario.com with dataset containing a red dress.',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/75ab65a8-1467-46e4-83fa-1d536f4afeb2/queue/59f83dd3-013e-45c2-bd7a-064653861088/fbc9d634-b07d-410f-849f-5a11d786e641.jpg',
            category: 'default',
            public_id: '',
            model_type: 'flux',
            defaultId: 1,
        },
        {
            name: 'Ski Touque',
            replace_name: null,
            description: 'A custom model trained on cartario.com with dataset containing a Ski Touque.',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/fe12d667-f9f8-49e4-8f61-a156490bcc1d/queue/765a8eb2-72d4-4cb7-ad16-ae20a14339bf/c6e5ee48-6bad-4d5b-aa8a-a376d90e5657.png',
            category: 'default',
            public_id: '',
            model_type: 'flux',
            defaultId: 4,
        },
        {
            name: 'Keira',
            replace_name: 'Keira',
            description: 'A custom model trained on cartario.com with dataset containing several images of an AI generated female model.',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/9c22925f-4d57-49b3-b136-58d4354aadbe/queue/941fb591-f3dc-424a-9d72-89ac79da96b8/8bf29767-074b-486b-9cd5-f62fe1defc9c.jpg',
            category: 'influencer',
            public_id: '',
            model_type: 'flux',
            defaultId: 5,
        },
        {
            name: 'Zoey',
            description: '',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/52658614-b616-4d5e-a3e9-7e959fe73215/queue/54190896-4c63-44a3-b6e6-df73e5a9fad1/5ad98a13-8ec2-40ad-ab73-e2edaae61e9a.jpg',
            category: 'influencer',
            public_id: '',
            model_type: 'flux',
            defaultId: 8,
        },
        {
            name: 'Becca',
            description: '',
            requiresBilling: false,
            image: 'https://apps.leadslide.com/media/images/collection/adcf1bb7-b5e1-4c0d-9a14-961026e505d0/queue/9ca926eb-b5b0-4491-9861-765dff917d40/7126bcd2-a9a9-49bf-b824-2e232797bd3c.jpg',
            category: 'influencer',
            public_id: '',
            model_type: 'flux',
            defaultId: 9,
        },
    ],
    loadedModels: false,
};

interface FetchModelsPayload {
    training_status?: string | null;
    state?: string | null;
    has_images?: boolean | null;
}

export const fetchModels = createAsyncThunk(
    'images/fetchModels',
    async (payload: FetchModelsPayload = { training_status: null, state: null, has_images: null }) => {
        // Build params object with non-null parameters
        const params: Record<string, string | boolean> = {};
        if (payload.training_status != null) {  // checks for both null and undefined
            params.training_status = payload.training_status;
        }
        if (payload.state != null) {
            params.state = payload.state;
        }

        if (payload.has_images != null) {
            params.has_images = payload.has_images;
        }

        const response = await API.get('/cartario/collections/?limit=1000', { params });
        const models = response.data.results;

        // Filter models to keep the latest unique public_id
        return models.reduce((acc: any[], currentModel: any) => {
            const existingModel = acc.find((m) => m.public_id === currentModel.public_id);

            // If no existing model or the current model has a more recent updated_at, use the current one
            if (!existingModel || new Date(currentModel.updated_at) > new Date(existingModel.updated_at)) {
                acc = acc.filter((m) => m.public_id !== currentModel.public_id); // Remove the older one
                acc.push(currentModel); // Add the newer one
            }
            return acc;
        }, []);
    }
);

// Fetch collections with pagination
export const fetchCollections = createAsyncThunk(
    'collections/fetchCollections',
    async ({ url, model_type, status }: { url?: string, model_type?:string, status?: string } = {}) => {
        let endpoint = url || API_URLS.COLLECTIONS.GET;
        if(model_type) {
            endpoint = `${endpoint}?model_type=${model_type}&state=active&status=${status}`;
        }
        const response = await API.get(endpoint);
        return response.data;
    }
);

// Fetch a single collection (model) detail
export const fetchCollectionDetail = createAsyncThunk(
    'collections/fetchCollectionDetail',
    async (public_id: string) => {
        const response = await API.get(`/cartario/collection/${public_id}/`);
        return response.data;
    }
);

export const generateAiInfluencer = createAsyncThunk(
    'collections/generateAiInfluencer',
    async (public_id: string) => {
        const response = await API.post(`/cartario/collection/${public_id}/generate-influencer/`);
        return response.data;
    }
);

// Fetch training status
export const fetchTrainingStatus = createAsyncThunk(
    'collections/fetchTrainingStatus',
    async (public_id: string) => {
        const response = await API.get(`/cartario/collection/${public_id}/training/status`);
        return response.data;
    }
);

export const trainModel = createAsyncThunk(
    'collections/trainModel',
    async ({ public_id }: { public_id: string; }, { dispatch, getState, rejectWithValue }) => {
        try {
            const response = await API.post(`/cartario/collection/${public_id}/train/`, {});

            // Update collection status to 'active' after a successful request
            const { currentCollection } = (getState() as RootState).collections;
            if (currentCollection && currentCollection.public_id === public_id) {
                dispatch(updateCollectionStatus('active'));
            }

            return response.data;
        } catch (error: any) {
            // Handle the error within the thunk and pass it back
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);


export const createCollection = createAsyncThunk(
    'collections/createCollection',
    async (data: { name: string; description?: string, training_type?:string }) => {
        const response = await API.post('/cartario/collections/', data);
        return response.data;
    }
);

export const uploadImage = createAsyncThunk(
    'collections/uploadImage',
    async (formData: FormData, { dispatch, getState }) => {
        try {
            // Axios will automatically set the Content-Type to multipart/form-data for FormData requests
            const response = await API.post('/cartario/images/', formData);

            const image = response.data;

            // Push the newly uploaded image to the current collection
            const { currentCollection } = (getState() as RootState).collections;
            if (currentCollection) {
                dispatch(addImageToCollection(image)); // Dispatch the reducer to add the image
            }

            return image;
        } catch (error) {
            console.error("Error uploading image:", error);
            throw error;
        }
    }
);

export const deleteImage = createAsyncThunk(
    'collections/deleteImage',
    async (imageId: number, { dispatch, getState, rejectWithValue }) => {
        try {
            await API.delete(`/cartario/image/${imageId}/`);

            // After deleting, update the state by removing the image
            const { currentCollection } = (getState() as RootState).collections;
            if (currentCollection) {
                dispatch(removeImageFromCollection(imageId)); // Use reducer to remove the image from state
            }
        } catch (error: any) {
            console.error("Error deleting image:", error);
            return rejectWithValue(error.response?.data || "Failed to delete image");
        }
    }
);

const updateCategoriesWithCollections = (collections: Collection[], models: CollectionCategory[]) => {
    collections.forEach(collection => {
        let category = null;
        category = models.find(cat => cat.defaultId === collection.default_id);

        if (category) {
            category.name = collection.name;
            category.public_id = collection.public_id;
            category.model_type = collection.model_type;
            category.trigger_word = collection.trigger_word;
        } else {

            models.push({
                name: collection.name,
                description: collection.description || '',
                requiresBilling: false,
                image: collection.image,
                category: 'custom',
                public_id: collection.public_id,
                model_type: 'custom',
                trigger_word: collection.trigger_word,
            });
        }
    });
};

const collectionSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        addImageToCollection: (state, action) => {
            if (state.currentCollection) {
                state.currentCollection.images.push(action.payload);
            }
        },
        updateCollectionStatus: (state, action) => {
            if (state.currentCollection) {
                state.currentCollection.training_status = action.payload;
            }
        },
        removeImageFromCollection: (state, action) => {
            if (state.currentCollection) {
                state.currentCollection.images = state.currentCollection.images.filter(
                    (image) => image.id !== action.payload
                );
            }
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetching collections
            .addCase(fetchCollections.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollections.fulfilled, (state, action) => {
                state.loading = false;
                state.collections = action.payload.results;
                state.count = action.payload.count;
                state.next = action.payload.next;
                state.previous = action.payload.previous;
            })
            .addCase(fetchCollections.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch collections';
            })

            // Fetching collection detail
            .addCase(fetchCollectionDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollectionDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.currentCollection = action.payload;
            })
            .addCase(fetchCollectionDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch collection details';
            })

            // Fetching training status
            .addCase(fetchTrainingStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTrainingStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.trainingStatus = action.payload;
                if (state.currentCollection) {
                    state.currentCollection.training_status = action.payload.status; // Update the training status in the collection
                }
            })
            .addCase(fetchTrainingStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch training status';
            })
            .addCase(uploadImage.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(uploadImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to upload image';
            })
            // Train Model Case
            .addCase(trainModel.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(trainModel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to train model';
            })
            .addCase(createCollection.fulfilled, (state, action) => {
                state.collections.push(action.payload); // Add the new collection to the state
            })
            .addCase(deleteImage.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteImage.rejected, (state) => {
                state.loading = false;
                state.error = 'Failed to delete image';
            })
            .addCase(generateAiInfluencer.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(generateAiInfluencer.fulfilled, (state, action) => {
                state.loading = false;
                state.currentCollection = action.payload;
            })
            .addCase(generateAiInfluencer.rejected, (state) => {
                state.loading = false;
                state.error = 'Failed to generate AI influencer';
            })
            .addCase(fetchModels.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchModels.fulfilled, (state, action) => {
                state.loading = false;
                updateCategoriesWithCollections(action.payload, state.models);
                state.loadedModels = true;
            })
            .addCase(fetchModels.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch models';
            })

    },
});

export const { addImageToCollection, updateCollectionStatus, removeImageFromCollection } = collectionSlice.actions;
export default collectionSlice.reducer;
