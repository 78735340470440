import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button as ChakraButton,
    VStack,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Input,
    Checkbox,
} from "@chakra-ui/react";
import { ChevronDownIcon } from 'lucide-react';
import {DefaultModelSetting} from "~/utils/types.ts";

interface SettingsDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    settings: DefaultModelSetting[];
    formData: Record<string, string>;
    onSettingChange: (key: string, value: string) => void;
}

const SettingsDrawer = ({ isOpen, onClose, settings, formData, onSettingChange }: SettingsDrawerProps) => {

    const getDisplayValue = (setting: DefaultModelSetting) => {
        if (setting.input_type === 'list') {
            const selectedOption = setting.options.find(opt => opt.key.toString() === formData[setting.key]?.toString());
            return selectedOption?.value || "Select Option";
        }
        return formData[setting.key] || "Select Option";
    };

    const renderSettingControl = (setting: DefaultModelSetting) => {
        switch (setting.input_type) {
            case "list":
                return (
                    <Menu>
                        <MenuButton
                            as={ChakraButton}
                            rightIcon={<ChevronDownIcon size={16} />}
                            bg="gray.700"
                            color="primary.0"
                            _hover={{ bg: 'gray.600' }}
                            w="100%"
                            mt={2}
                        >
                            {getDisplayValue(setting)}
                        </MenuButton>
                        <MenuList bg="gray.700" borderColor="gray.600">
                            {setting.options.map((option) => (
                                <MenuItem
                                    key={option.key}
                                    onClick={() => onSettingChange(setting.key, option.key.toString())}
                                    bg="gray.700"
                                    color="primary.0"
                                    _hover={{ bg: 'gray.600' }}
                                >
                                    {option.value}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                );

            case "text":
                return (
                    <Input
                        value={formData[setting.key] || ""}
                        onChange={(e) => onSettingChange(setting.key, e.target.value)}
                        bg="gray.700"
                        color="primary.0"
                        _hover={{ bg: 'gray.600' }}
                        mt={2}
                    />
                );

            case "number":
                return (
                    <Input
                        type="number"
                        value={formData[setting.key] || ""}
                        onChange={(e) => onSettingChange(setting.key, e.target.value)}
                        bg="gray.700"
                        color="primary.0"
                        _hover={{ bg: 'gray.600' }}
                        mt={2}
                    />
                );

            case "boolean":
                return (
                    <Checkbox
                        isChecked={formData[setting.key] === "true"}
                        onChange={(e) => onSettingChange(setting.key, e.target.checked.toString())}
                        colorScheme="blue"
                        mt={2}
                    >
                        {setting.description}
                    </Checkbox>
                );

            default:
                return null;
        }
    };

    return (
        <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
            <DrawerOverlay />
            <DrawerContent bg="gray.800" color="white">
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px" borderColor="gray.700">
                    Settings
                </DrawerHeader>
                <DrawerBody>
                    <VStack spacing={6} align="stretch">
                        {!settings.length && (
                            <Alert
                                status="info"
                                variant="solid"
                                bg="gray.700"
                                borderRadius="md"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                                height="200px"
                            >
                                <AlertIcon boxSize="40px" mr={0} mb={4} />
                                <AlertTitle mb={2} fontSize="lg">No Settings Available</AlertTitle>
                                <AlertDescription>
                                    This model doesn't have any configurable settings at the moment.
                                </AlertDescription>
                            </Alert>
                        )}
                        {settings.map((setting) => (
                            <div key={setting.key}>
                                {setting.input_type !== 'boolean' && (
                                    <Text color="gray.300" mb={2}>{setting.description}</Text>
                                )}
                                {renderSettingControl(setting)}
                            </div>
                        ))}
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default SettingsDrawer;