import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "~/store";
import React, {useEffect} from "react";
import {Box, SimpleGrid, Text, VStack} from "@chakra-ui/react";
import Loader from "~/apps/backend/components/tools/Loader.tsx";
import {AiCatSelectOnClickPayload} from "~/utils/types.ts";
import {fetchDefaultModels} from "~/store/modelSlice.ts";

interface AiCategorySelectProps {
    onClick: (payload: AiCatSelectOnClickPayload) => void;
    categoryFilter?: string;
    showTitle?: boolean;
}

const AiCategorySelect: React.FC<AiCategorySelectProps> = ({ onClick, showTitle = true, categoryFilter = ''  }) => {
    const { models, loading, loadedModels } = useSelector((state: RootState) => state.models);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if(!loadedModels) {
            dispatch(fetchDefaultModels());
        }
    }, []);

    const categories = [
        'flux',
        'product',
        'influencer',
        'other',
        'custom'
    ]

    return (
        <>
            {loading && <Loader />}
            <VStack spacing={8} align="stretch">
                {categories.filter(o => o.includes(categoryFilter || '')).map((category) => (
                    <Box key={category}>
                        {showTitle && (
                            <Text
                                fontSize="xl"
                                fontWeight="bold"
                                mb={4}
                                textTransform="capitalize"
                            >
                                {category}
                            </Text>
                        )}

                        {models.filter(o => o.category === category).length === 0 ? (
                            <Box
                                borderWidth="1px"
                                borderColor="gray.600"
                                borderRadius="md"
                                p={4}
                                bg="gray.700"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                gap={2}
                            >
                                <Text color="gray.400">
                                    No models available in this category
                                </Text>
                                <Text fontSize="sm" color="gray.500">
                                    Check back later for new additions
                                </Text>
                            </Box>
                        ) : (
                            <SimpleGrid
                                columns={{ base: 1, sm: 2, md: 4, lg: 4, xl: 6 }}
                                spacing={4}
                            >
                                {models.filter(o => o.category === category).map((model, modelIndex) => (
                                    <Box
                                        key={`${modelIndex}_${model.public_id}`}
                                        position="relative"
                                        w="100%"
                                        maxW="200px"
                                        h="200px"
                                        borderWidth="1px"
                                        borderColor="white"
                                        borderRadius="md"
                                        overflow="hidden"
                                        mx="auto"
                                        cursor="pointer"
                                        onClick={() => onClick({
                                            publicId: model.public_id,
                                            category: model.category
                                        })}
                                        _hover={{
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                bg: 'rgba(0, 0, 0, 0.2)',
                                                zIndex: 1
                                            }
                                        }}
                                    >
                                        <Box
                                            w="100%"
                                            h="100%"
                                            bgImage={`url(${model.full_image})`}
                                            bgSize="cover"
                                            bgPosition="center"
                                        />
                                        <Box
                                            position="absolute"
                                            bottom={0}
                                            left={0}
                                            right={0}
                                            bg="rgba(0, 0, 0, 0.7)"
                                            p={2}
                                            zIndex={2}
                                        >
                                            <Text
                                                color="white"
                                                fontSize="sm"
                                                fontWeight="medium"
                                                textAlign="center"
                                                noOfLines={1}
                                            >
                                                {model.name}
                                            </Text>
                                        </Box>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        )}
                    </Box>
                ))}
            </VStack>
        </>
    );
}

export default AiCategorySelect;