import {
    Box,
    VStack,
    IconButton,
    Select,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    HStack,
    Divider, Tooltip
} from '@chakra-ui/react';
import {CopyIcon, DeleteIcon} from "@chakra-ui/icons";
import {Layer} from "~/utils/types.ts";
import {useLayerManagement} from "~/hooks/useLayerManagement.ts";

export interface LayerToolbarProps {
    layer: Layer;
    onUpdate: (updates: Partial<Layer>) => void;
    onRmBg: (type: string, layer: Layer) => void;
    onDelete: () => void;
    onClone: () => void;
}

const FONT_SIZES = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'];
const FONTS = ['Chakra UI Default', 'Open Sans', 'PT Sans'];

export default function LayerToolbar({ layer, onUpdate, onDelete, onClone, onRmBg }: LayerToolbarProps) {
    if(!layer) {
        return null;
    }

    const { handleToolToggle } = useLayerManagement();

    const updateStyle = (updates: object) => {
        onUpdate({
            style: { ...layer.style, ...updates }
        });
    };

    const isResizeActive = layer?.tools?.find(tool => tool.name === 'resize')?.isActive || false;

    return (
        <Box p={4} borderWidth="1px" borderRadius="lg" bg="whiteAlpha.100" backdropFilter="blur(8px)">
            <VStack spacing={4} align="stretch">
                <HStack spacing={4} justify="flex-end">
                    {layer.status === 'active' && (
                        <>
                            <Tooltip label="Clone Layer">
                                <IconButton
                                    aria-label="Clone Layer"
                                    icon={<CopyIcon />}
                                    variant="ghost"
                                    color="primary.0"
                                    onClick={onClone}
                                />
                            </Tooltip>
                            <Tooltip label="Delete Layer">
                                <IconButton
                                    aria-label="Delete Layer"
                                    icon={<DeleteIcon />}
                                    size="lg"
                                    variant="ghost"
                                    colorScheme="red"
                                    onClick={onDelete}
                                />
                            </Tooltip>
                        </>
                    )}
                </HStack>

                <VStack align="stretch" spacing={2}>
                    <Text fontSize="sm" fontWeight="medium" color="gray.300">
                        Opacity
                    </Text>
                    <Slider
                        value={Number(layer.style?.opacity ?? 1)}
                        min={0}
                        max={1}
                        step={0.1}
                        onChange={(v: number) => updateStyle({ opacity: v })}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>

                    <Divider mt={4} mb={4} />

                    <HStack align="center">
                        <Tooltip label={isResizeActive ? 'Disable Resize': 'Enable Resize'}>
                            <Button
                                aria-label={isResizeActive ? 'Disable Resize': 'Enable Resize'}
                                size="md"
                                variant="outline"
                                w="100%"
                                color="primary.0"
                                _hover={{ bg: "gray.900" }}
                                onClick={() => handleToolToggle('resize')}

                            >
                                {isResizeActive ? 'Disable': 'Enable'} Resize
                            </Button>
                        </Tooltip>
                    </HStack>
                </VStack>

                {layer.type === 'text' && (
                    <>
                        <VStack align="stretch" spacing={2}>
                            <Text fontSize="sm" fontWeight="medium" color="gray.300">
                                Font Size
                            </Text>
                            <Select
                                size="sm"
                                value={layer.style?.fontSize ?? 'md'}
                                onChange={(e) => updateStyle({ fontSize: e.target.value })}
                            >
                                {FONT_SIZES.map(size => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </Select>

                            <Text fontSize="sm" fontWeight="medium" color="gray.300">
                                Font Family
                            </Text>
                            <Select
                                size="sm"
                                value={layer.style?.fontFamily}
                                onChange={(e) => updateStyle({ fontFamily: e.target.value })}
                            >
                                {FONTS.map(font => (
                                    <option key={font} value={font}>{font}</option>
                                ))}
                            </Select>
                        </VStack>
                    </>
                )}

                {layer.type === 'image' && !layer.other_values?.backgroundRemoved && (
                    <Box minH={200} mt={2}>

                        <Menu >
                            <MenuButton as={Button} size="sm" w="full" colorScheme="blue">
                                Remove Background
                            </MenuButton>
                            <MenuList zIndex={9000} bg="gray.800">
                                <MenuItem bg="gray.800" onClick={() => onRmBg('simple', layer)}>
                                    Simple Image
                                </MenuItem>
                                <MenuItem bg="gray.800" onClick={() => onRmBg('complex', layer)}>
                                    Complex Image
                                </MenuItem>
                                <MenuItem bg="gray.800" onClick={() => onRmBg('human', layer)}>
                                    Human Portrait
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Box>
                )}
            </VStack>
        </Box>
    );
}