// src/apps/frontend/routes/index.tsx
import {Routes, Route, Navigate} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Models from "~/apps/backend/pages/Models.tsx";
import Billing from "~/apps/backend/pages/Billing.tsx";
import ModelDetail from "~/apps/backend/pages/ModelDetail.tsx";
import MyImages from "~/apps/backend/pages/MyImages.tsx";
import Support from "~/apps/backend/pages/Support.tsx";
import Tutorials from "~/apps/backend/pages/Tutorials.tsx";
import EditImage from "~/apps/backend/pages/EditImage.tsx";
import GenerateImages from "~/apps/backend/pages/GenerateImages.tsx";
import GenerateModelImage from "~/apps/backend/pages/GenerateModelImage.tsx";
import Generate from "~/apps/backend/pages/Generate.tsx";
import Profile from "~/apps/backend/pages/Profile.tsx";
import Products from "~/apps/backend/pages/Products.tsx";
import ProductChangeBackground from "~/apps/backend/pages/ProductChangeBackground.tsx";

export const BackendRoutes = () => {
    return (
        <MainLayout>
            <Routes>
                <Route path='/' element={<Models />}/>
                <Route path='/models' element={<Models />}/>
                <Route path="/model/:public_id" element={<ModelDetail />} />
                <Route path="/images" element={<GenerateImages />} />
                <Route path="/generate-images" element={<GenerateImages />} />
                <Route path="/generate-images/:public_id" element={<GenerateModelImage />} />
                <Route path="/generate/:public_id/:category" element={<Generate />} />
                <Route path="/modify-image/:public_id" element={<EditImage />} />
                <Route path="/my-images" element={<MyImages />} />
                <Route path='/billing' element={<Billing />}/>
                <Route path='/support' element={<Support />}/>
                <Route path='/tutorials' element={<Tutorials />}/>
                <Route path='/profile' element={<Profile />}/>
                <Route path='/products' element={<Products />}/>
                <Route path='/product/:public_id/change-background' element={<ProductChangeBackground />}/>
                <Route path="/*" element={<Navigate to="/errors/404" replace />} />
            </Routes>
        </MainLayout>
    );
}
