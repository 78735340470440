import {Box} from "@chakra-ui/react";

import {useNavigate} from "react-router-dom";
import {GalleryImage} from "~/utils/types.ts";
import GallerySelect from "~/apps/backend/components/GallerySelect.tsx";
import {Product} from "~/store/gallerySlice.ts";

const MyImages = () => {

    const navigate = useNavigate();

    const onImageSelect = (image: GalleryImage | Product) => {
        navigate(`/c-panel/modify-image/${image.public_id}`)
    }

    return (
        <Box p={6} bg="gray.800" minH="100vh">
            <GallerySelect onImageSelect={onImageSelect} showOtherOptions={true} selectText="Edit" />
        </Box>
    );
}

export default MyImages;