import React from "react";
import { Download, Pencil, CheckIcon } from 'lucide-react';
import {
    Box,
    IconButton,
    Tooltip,
    useToast
} from '@chakra-ui/react';
import {GenerationResponse} from "~/store/modelSlice.ts";
import {downloadImage} from "~/store/imageSlice.ts";
import {useDispatch} from "react-redux";
import {AppDispatch} from "~/store";


interface ImageBoxProps {
    result: GenerationResponse;
    category: string | undefined;
    index: number;
    onImageSelect?: (image: GenerationResponse) => void;
}

const ImageResult: React.FC<ImageBoxProps> = ({index, category, result, onImageSelect }) => {
    const toast = useToast();
    const dispatch = useDispatch<AppDispatch>();

    const handleDownload = async () => {
        const resultAction = await dispatch(downloadImage({ public_id: result.image_id, category: category }));
        if (downloadImage.fulfilled.match(resultAction)) {
            toast({
                title: 'Download succeeded',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Download failed',
                description: resultAction.payload || 'An error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }    };

    return (
        <Box
            key={index}
            position="relative"
            mb={4}
            maxW="full"
            borderRadius="md"
            overflow="hidden"
        >
            <Box
                position="absolute"
                top={2}
                right={2}
                display="flex"
                gap={2}
            >
                {typeof onImageSelect === 'function' ? (
                    <>
                        <Tooltip
                            label="Select Image"
                            hasArrow
                            placement="top"
                        >
                            <IconButton
                                icon={<CheckIcon size={16} />}
                                size="sm"
                                variant="solid"
                                onClick={() => onImageSelect(result)}
                                aria-label="Select Image"
                                borderRadius="full"
                                color="green"
                                bg="green.100"
                                _hover={{ bg: "green", color: "primary.0" }}
                            />
                        </Tooltip>
                    </>
                ): (
                    <>
                        <Tooltip
                            label="Edit Image"
                            hasArrow
                            placement="top"
                            bg="gray.700"
                        >
                            <IconButton
                                icon={<Pencil size={16} />}
                                size="sm"
                                colorScheme="whiteAlpha"
                                variant="solid"
                                onClick={() => window.open(`/c-panel/modify-image/${result.image_id}`, '_blank')}
                                aria-label="Edit Image"
                                borderRadius="full"
                                bg="whiteAlpha.800"
                                color="gray.700"
                                _hover={{ bg: "white", color: "blue.500" }}
                            />
                        </Tooltip>

                        <Tooltip
                            label="Download Image"
                            hasArrow
                            placement="top"
                            bg="gray.700"
                        >
                            <IconButton
                                icon={<Download size={16} />}
                                size="sm"
                                colorScheme="whiteAlpha"
                                variant="solid"
                                onClick={handleDownload}
                                aria-label="Download Image"
                                borderRadius="full"
                                bg="whiteAlpha.800"
                                color="gray.700"
                                _hover={{ bg: "white", color: "blue.500" }}
                            />
                        </Tooltip>
                    </>
                )}

            </Box>

            <Box
                as="img"
                src={result.image_url}
                alt="Generated Result"
                maxH="400px"
                w="full"
                objectFit="contain"
            />
        </Box>
    );
};

export default ImageResult;