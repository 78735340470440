import {useParams} from "react-router-dom";

import {
    Box,
    Container,
} from "@chakra-ui/react";
import GenerateImage from "~/apps/backend/components/GenerateImage.tsx";


const Generate = () => {
    const { public_id, category } = useParams<{ public_id: string; category: string }>();

    return (
        <Box
            bg="gray.900"
            minH="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Container maxW="container.md" py={8} px={4}>
                <GenerateImage public_id={public_id} category={category} includeSettings={true} />
            </Container>
        </Box>
    );
};

export default Generate;