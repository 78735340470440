// authSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../utils/api';

export interface User {
    email: string;
    first_name: string;
    last_name: string;
    credits: number;
    extra_credits: number;
    public_id: string;
    is_google_user: boolean;
}

interface AuthState {
    token: string | null;
    refreshToken: string | null;
    loading: boolean;
    error: string | null;
    passwordResetSuccess: boolean;
    me: User | null
}

const tokenFromStorage = localStorage.getItem('token');
const refreshTokenFromStorage = localStorage.getItem('refreshToken');

const initialState: AuthState = {
    token: tokenFromStorage || null,
    refreshToken: refreshTokenFromStorage || null,
    loading: false,
    error: null,
    passwordResetSuccess: false,
    me: null,
};

export const fetchUser = createAsyncThunk('auth/fetchUser', async () => {
    const response = await API.get('/auth/me/');
    return response.data as User;
});

export const updateProfile = createAsyncThunk('auth/updateProfile', async (data: { first_name: string; last_name: string }) => {
    const response = await API.post('/auth/me/', data);
    return response.data as User;
});

export const updatePassword = createAsyncThunk('auth/updatePassword', async (data: { current_password: string; new_password: string }) => {
    await API.post('/auth/update-password/', data);
    return null;
});

// Login thunk
export const login = createAsyncThunk('auth/login', async (credentials: { username: string; password: string; captcha_token: string; }) => {
    const response = await API.post('/auth/login/', credentials);
    return response.data;
});

// authSlice.ts
export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (email: string, { rejectWithValue }) => {
        try {
            const response = await API.post('/auth/forgot-password/', { email });
            return response.data;
        } catch (error: any) {
            const message =
                typeof error.response?.data === 'string'
                    ? error.response.data
                    : typeof error.response?.data === 'object'
                        ? JSON.stringify(error.response.data)
                        : error.message || 'Failed to send reset email';
            return rejectWithValue(message);
        }
    }
);

export const googleLogin = createAsyncThunk(
    'auth/googleLogin',
    async (idToken: string) => {
        const response = await API.post('/auth/google-login/', { token: idToken });
        return response.data;
    }
);

// Register thunk
export const register = createAsyncThunk(
    'auth/register',
    async (userData: { email: string; password: string; captcha_token:string }, { rejectWithValue }) => {
        try {
            const response = await API.post('/auth/register/', userData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Registration failed');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            state.token = null;
            state.refreshToken = null;
        },
        clearPasswordResetState: (state) => {
            state.passwordResetSuccess = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.token = action.payload.access;
                state.refreshToken = action.payload.refresh;
                localStorage.setItem('token', action.payload.access);
                localStorage.setItem('refreshToken', action.payload.refresh);
                state.loading = false;
                state.error = null;
            })
            .addCase(register.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.passwordResetSuccess = true;
                state.loading = false;
                state.error = null;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.passwordResetSuccess = false;
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(googleLogin.fulfilled, (state, action) => {
                state.token = action.payload.access;
                state.refreshToken = action.payload.refresh;
                localStorage.setItem('token', action.payload.access);
                localStorage.setItem('refreshToken', action.payload.refresh);
            })
            .addCase(fetchUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUser.rejected, (state) => {
                state.loading = false;
                state.error = 'Failed to fetch user';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.me = action.payload;
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateProfile.rejected, (state) => {
                state.loading = false;
                state.error = 'Failed to update profile';
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.me = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(updatePassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePassword.rejected, (state) => {
                state.loading = false;
                state.error = 'Failed to update password';
            })
            .addCase(updatePassword.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            });

    },
});

export const { logout, clearPasswordResetState } = authSlice.actions;
export default authSlice.reducer;
